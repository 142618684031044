import { ApiUrls } from '@modules/constants';

import { adminAxios } from '../admin-axios';

interface DeleteProductRequestParams {
  productId: string;
}

export const deleteProduct = ({ productId }: DeleteProductRequestParams) => {
  return adminAxios.delete<Boolean>(ApiUrls.ADMIN_PRODUCT_BY_ID(productId));
};
