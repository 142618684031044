import { ApiUrls } from '@modules/constants';

import { adminAxios } from '../admin-axios';
import { CombinationUploadImageResponseDto } from './types';


export interface UploadCombinationImageRequestParams {
  file: File;
  combinationId: string;
}

export const uploadCombinationImage = ({ file, combinationId }: UploadCombinationImageRequestParams) => {
  const formData = new FormData();

  formData.append('file', file);

  return adminAxios.post<CombinationUploadImageResponseDto>(
    ApiUrls.ADMIN_COMBINATION_UPLOAD_IMAGE(combinationId),
    formData
  );
};
