import { ApiUrls } from '@modules/constants';

import { adminAxios } from '../admin-axios';

interface DeleteSubcategoryRequestParams {
  categoryId: string;
  id: string;
}

export const deleteSubcategory = ({
  categoryId,
  id,
}: DeleteSubcategoryRequestParams) => {
  return adminAxios.delete<boolean>(
    ApiUrls.ADMIN_SUBCATEGORY_BY_ID(categoryId, id)
  );
};
