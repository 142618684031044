import { createCombination } from './create-combination';
import { editCombination } from './edit-combination';

interface SaveCombinationRequestParams {
  id?: string;
  name: string;
  description: string;
  isVisible: boolean;
}

export const saveCombination = ({
  id,
  ...params
}: SaveCombinationRequestParams) => {
  if (id) {
    return editCombination({ id, ...params });
  }
  return createCombination(params);
};
