import { ApiUrls, CombinationPartType } from '@modules/constants';

import { adminAxios } from '../admin-axios';

interface EditCombinationPartSuccessResponseData {
  id: string;
  part: CombinationPartType;
  isVisible: boolean;
  product: {
    id: string;
    name: string;
  };
}

interface EditPartCombinationRequestParams {
  id: string;
  part: CombinationPartType;
  productId: string;
  combinationId: string;
  isVisible: boolean;
}

export const editPart = ({
  id,
  part,
  productId,
  combinationId,
  isVisible,
}: EditPartCombinationRequestParams) => {
  return adminAxios.put<EditCombinationPartSuccessResponseData>(
    ApiUrls.ADMIN_PART_BY_ID(combinationId, id),
    {
      part,
      product: productId,
      combination: combinationId,
      isVisible,
    }
  );
};
