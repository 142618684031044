import { memo, useCallback } from 'react';
import 'react-color-palette/css';
import { useController } from 'react-hook-form';

import { FilePicker, SelectedFile } from '@ui-kit/file-picker';

import { FormValues } from '../types';

interface ImagePickerProps {
  onChangeFile: (value?: SelectedFile | undefined) => void;
}

export const ImagePicker = memo<ImagePickerProps>((props) => {
  const { onChangeFile } = props;

  const {
    field: { value, onChange },
  } = useController<FormValues>({
    name: 'imgUrl',
    rules: {
      required: true,
    },
  });

  const handleChangeFile = useCallback(
    (file?: SelectedFile | undefined) => {
      if (!file) {
        return;
      }
      onChange(file?.url);
      onChangeFile(file);
    },
    [onChange, onChangeFile]
  );

  return (
    <>
      <FilePicker onChange={handleChangeFile} defaultUrls={value} />
    </>
  );
});
