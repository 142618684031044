export default (
  <>
    <path
      d="M5 12L19 12M5 12L12 5M5 12L12 19"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
