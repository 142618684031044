import { Box, Button, styled } from '@mui/material';

export const StyledContainer = styled(Box)`
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 78px;
  border: 1px dashed rgba(139, 137, 137, 1);
  border-radius: 8px;
`;

export const StyledInput = styled('input')`
  opacity: 0;
  position: absolute;
  z-index: 1;
  top: -24px;
  left: 0;
  width: 100%;
  height: calc(100% + 24px);
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden !important;
`;

export const StyledButton = styled(Button)`
  border: none;
  background-color: rgba(246, 246, 246, 1);
  background-size: cover;
  background-position: center;

  &:hover {
    border: none;
  }
  color: #fff;

  svg {
    width: 32px;
    height: 32px;
    padding: 4px;
    border-radius: 50%;
    background: rgba(139, 137, 137, 1);
  }
`;
