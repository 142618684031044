import { ApiUrls, CombinationPartType } from '@modules/constants';

import { adminAxios } from '../admin-axios';

interface CreateCategorySuccessResponseData {
  id: string;
  name: string;
}

interface CreateCategoryRequestParams {
  name: string;
  parts?: CombinationPartType[];
}

export const createCategory = ({
  name,
  parts,
}: CreateCategoryRequestParams) => {
  const p = parts?.filter((v) => !!v);
  return adminAxios.post<CreateCategorySuccessResponseData>(
    ApiUrls.ADMIN_CATEGORIES,
    {
      name,
      ...(p?.length ? { parts: p } : {}),
    }
  );
};
