import { useSnackbar } from 'notistack';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { login } from '@adapters/admin/auth';
import { Urls } from '@modules/constants';
import { AppStorage } from '@modules/storage';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

interface Form {
  email: string;
  password: string;
}

export const LoginForm: FC = () => {
  const { register, handleSubmit: formSubmit } = useForm<Form>();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { isLoading, mutate: authRequest } = useMutation(login, {
    onSuccess: (data) => {
      if (data) {
        AppStorage.set({
          key: 'ADMIN_AUTH',
          value: data.data,
        });
        navigate(Urls.ADMIN);
      }
    },
    onError: () => {
      enqueueSnackbar(
        'Что-то пошло не так. Пожалуйста, проверьте введенные данные и попробуйте еще раз',
        {
          variant: 'error',
        }
      );
    },
  });

  const handleSubmit = (data: Form) => {
    authRequest(data);
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Paper sx={{ minWidth: '488px' }}>
      <form onSubmit={formSubmit(handleSubmit)}>
        <Stack alignItems="center" spacing={4}>
          <img src="/assets/logo.svg" alt="" />
          <Typography variant="subtitle1">Вход в аккаунт</Typography>
          <Stack spacing={2} width="100%">
            <TextField
              {...register('email', { required: true })}
              placeholder="Почта"
            />
            <TextField
              {...register('password', { required: true })}
              type={!showPassword ? 'password' : 'text'}
              placeholder="Пароль"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setShowPassword((prev) => !prev);
                      }}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Button type="submit" fullWidth variant="contained">
            Войти в аккаунт
          </Button>
        </Stack>
      </form>
    </Paper>
  );
};
