import { memo } from 'react';

import { DeleteDialog } from '@ui-kit/delete-dialog';

interface LimitDialogProps {
  onClose: () => void;
  onDelete: () => void;
  isVisible?: boolean;
}

export const LimitDialog = memo<LimitDialogProps>(
  ({ onClose, onDelete, isVisible }) => {
    if (!isVisible) {
      return <></>;
    }
    return (
      <DeleteDialog
        title="Лимит достигнут!"
        subtitle="Вы достигли максимального количества допустимых генераций на данном фото. Начните сначала или вернитесь назад, чтобы выбрать из готовых вариантов"
        cancelText="вернуться назад"
        confirmText="начать сначала"
        open={isVisible}
        onClose={onClose}
        onConfirm={onDelete}
      />
    );
  }
);
