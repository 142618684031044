import { FC } from 'react';
import { Link } from 'react-router-dom';

import { Urls } from '@modules/constants';
import { AppBar, Stack } from '@mui/material';
import { UserProfile } from '@widgets/header/components/user-profile';

export const Header: FC = () => {
  return (
    <AppBar position="static">
      <Stack direction="row" justifyContent="space-between">
        <Link to={Urls.ADMIN}>
          <img style={{ height: '38px' }} src="/assets/logo.svg" alt="" />
        </Link>
        <UserProfile />
      </Stack>
    </AppBar>
  );
};
