import { TableCell as MUITableCell, styled } from '@mui/material';

export const Container = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;

export const TableCell = styled(MUITableCell)`
  padding: 0px 8px;
`;
