import { ApiUrls } from '@modules/constants';

import { userAxios } from '../user-axios';

interface TestUserSuccessResponseData {
  id: string;
  role: string;
}

export const testUser = () => {
  return userAxios.post<TestUserSuccessResponseData>(ApiUrls.USER_GET_USER);
};
