import axios from 'axios';

import { ApiUrls, BASE_API } from '@modules/constants';

interface RefreshSuccessResponseData {
  accessToken: string;
  refreshToken: string;
}

export const refresh = (refreshToken?: string) =>
  axios.post<RefreshSuccessResponseData>(
    ApiUrls.ADMIN_REFRESH,
    {
      refreshToken,
    },
    {
      baseURL: BASE_API,
    }
  );
