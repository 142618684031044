import { FC } from 'react';

import { Stack, Typography } from '@mui/material';

export const LeftInfo: FC = () => {
  return (
    <Stack gap={4} maxWidth={472} justifyContent="center">
      <Typography
        variant="h4"
        sx={{
          fontSize: 32,
          fontWeight: 700,
        }}
        color={(theme) => theme.palette.primary.main}
      >
        Выбери свой дизайн
      </Typography>
      <Typography
        sx={{
          color: '#000',
          fontFamily: 'Open Sans',
          fontSize: 16,
          fontStyle: 'normal',
          fontWeight: 400,
        }}
      >
        Вам не нужно тратить время и придумывать дизайн для своего дома — просто
        выберите то, что вам понравится из предложенных вариантов Grand Line!
      </Typography>
    </Stack>
  );
};
