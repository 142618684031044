import {
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import { AdminLayout } from '@layouts/admin-layout';
import { LoginLayout } from '@layouts/login-layout';
import { MainLayout } from '@layouts/main-layout';
import { Urls } from '@modules/constants';
import { Alert } from '@mui/material';
import { CategoryList } from '@pages/category-list';
import { CombinationPage } from '@pages/combination-page';
import { CombinationsList } from '@pages/combinations-list';
import { LoginPage } from '@pages/login-page';
import { ProductsList } from '@pages/products-list';
import { SubcategoryList } from '@pages/subcategory-list';
import { UserProductPage } from '@pages/user-product-page';

import { PrivateAreaWrapper } from './components/private-area';
import { UserAreaWrapper } from './components/user-area';

export const routes = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        path={Urls.ADMIN}
        element={
          <PrivateAreaWrapper>
            <AdminLayout />
          </PrivateAreaWrapper>
        }
      >
        <Route index element={<Navigate to={Urls.ADMIN_CATEGORIES} />} />
        <Route path={Urls.ADMIN_CATEGORIES}>
          <Route index element={<CategoryList />} />
          <Route
            path={Urls.ADMIN_CATEGORY.path}
            element={<SubcategoryList />}
          />
        </Route>

        <Route path={Urls.ADMIN_PRODUCTS} element={<ProductsList />} />
        <Route path={Urls.ADMIN_COMBINATIONS}>
          <Route index element={<CombinationsList />} />
          <Route
            path={Urls.ADMIN_COMBINATION.path}
            element={<CombinationPage />}
          />
        </Route>
      </Route>

      <Route path={Urls.ADMIN_LOGIN} element={<LoginLayout />}>
        <Route index element={<LoginPage />} />
      </Route>

      <Route
        path={Urls.HOME}
        element={
          <UserAreaWrapper>
            <MainLayout />
          </UserAreaWrapper>
        }
      >
        <Route index element={<UserProductPage />}></Route>
      </Route>

      <Route
        path="*"
        element={<Alert severity="error">404. Страница не найдена</Alert>}
      />
    </>
  )
);
