import { ApiUrls } from '@modules/constants';

import { adminAxios } from '../admin-axios';
import { ProductResponseDto } from './types';

interface GetProductsSuccessResponseData {
  total: number;
  data: ProductResponseDto[];
}

interface GetProductsRequestParams {
  page: number;
  pageSize: number;
  search?: string;
  filterCategories?: string[];
}

export const getProducts = ({
  page,
  pageSize,
  search,
  filterCategories,
}: GetProductsRequestParams) => {
  return adminAxios.get<GetProductsSuccessResponseData>(
    ApiUrls.ADMIN_PRODUCTS,
    {
      params: {
        page,
        pageSize,
        sorts: 'name:asc',
        ...(search ? { search } : {}),
        ...(filterCategories
          ? { filters: `category:${filterCategories.join(',')}` }
          : {}),
      },
    }
  );
};
