import { Box, Button, styled } from '@mui/material';
import Icons from '@ui-kit/icons';

export const StyledInput = styled('input')`
  opacity: 0;
  position: absolute;
  z-index: 1;
  top: -24px;
  left: 0;
  width: 100%;
  height: calc(100% + 24px);
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden !important;
`;

export const StyledButton = styled(Button)`
  width: 288px;
  height: 48px;
`;

export const ImageIcon = styled(Icons.Image)`
  width: 96px;
  height: 96px;
  color: #a50023;
  flex-shrink: 0;
`;

export const Li = styled('li')`
  color: #231f20;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
`;
export const LiTitle = styled('p')`
  color: #231f20;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
`;

export const Format = styled('p')`
  color: #686566;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;
export const OrText = styled('p')`
  color: #8b8989;
  font-family: 'Open Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;
export const Line = styled(Box)`
  width: 148px;
  height: 1px;
  background: #d0cfcf;
`;
