import memoizeOne from 'memoize-one';

import AutumnSvgPath from './icons/autumn';
import BackArrowSvgPath from './icons/back-arrow';
import CategoriesSvgPath from './icons/categories';
import ClearSvgPath from './icons/clear';
import CombinationsSvgPath from './icons/combinations';
import DownloadSvgPath from './icons/download';
import EditSvgPath from './icons/edit';
import ImageSvgPath from './icons/image';
import LeftArrowSvgPath from './icons/left-arrow';
import LoadErrorSvgPath from './icons/load-error';
import MoonSvgPath from './icons/moon';
import PinSvgPath from './icons/pin';
import ProductsSvgPath from './icons/products';
import RepeatSvgPath from './icons/repeat';
import RightArrowSvgPath from './icons/right-arrow';
import SpringSvgPath from './icons/spring';
import SummerSvgPath from './icons/summer';
import SunSvgPath from './icons/sun';
import TrashSvgPath from './icons/trash';
import UpArrowSvgPath from './icons/up-arrow';
import WinterSvgPath from './icons/winter';
import { createIcon } from './utils/create-icon';

const memoizedCreateIcon = memoizeOne(createIcon);

const size24x25SvgProps = {
  viewBox: '0 0 24 25',
  fill: 'none',
};
const size24x24SvgProps = {
  viewBox: '0 0 24 24',
  fill: 'none',
};
const size96x97SvgProps = {
  viewBox: '0 0 96 97',
  fill: 'none',
};

export const Icons = {
  get Autumn() {
    return memoizedCreateIcon('Autumn', AutumnSvgPath, size24x24SvgProps);
  },
  get BackArrow() {
    return memoizedCreateIcon('BackArrow', BackArrowSvgPath, size24x24SvgProps);
  },
  get Categories() {
    return memoizedCreateIcon(
      'Categories',
      CategoriesSvgPath,
      size24x25SvgProps
    );
  },
  get Clear() {
    return memoizedCreateIcon('Clear', ClearSvgPath, size24x25SvgProps);
  },
  get Combinations() {
    return memoizedCreateIcon(
      'Combinations',
      CombinationsSvgPath,
      size24x25SvgProps
    );
  },
  get Download() {
    return memoizedCreateIcon('Download', DownloadSvgPath, size24x24SvgProps);
  },
  get LeftArrow() {
    return memoizedCreateIcon('LeftArrow', LeftArrowSvgPath, size24x24SvgProps);
  },
  get LoadError() {
    return memoizedCreateIcon('LoadError', LoadErrorSvgPath, size24x24SvgProps);
  },
  get Edit() {
    return memoizedCreateIcon('Edit', EditSvgPath, size24x24SvgProps);
  },
  get Image() {
    return memoizedCreateIcon('Image', ImageSvgPath, size96x97SvgProps);
  },
  get Moon() {
    return memoizedCreateIcon('Moon', MoonSvgPath, size24x24SvgProps);
  },
  get Pin() {
    return memoizedCreateIcon('Pin', PinSvgPath, size24x24SvgProps);
  },
  get Products() {
    return memoizedCreateIcon('Products', ProductsSvgPath, size24x25SvgProps);
  },
  get Repeat() {
    return memoizedCreateIcon('Repeat', RepeatSvgPath, size24x24SvgProps);
  },
  get RightArrow() {
    return memoizedCreateIcon(
      'RightArrow',
      RightArrowSvgPath,
      size24x24SvgProps
    );
  },
  get Spring() {
    return memoizedCreateIcon('Spring', SpringSvgPath, size24x24SvgProps);
  },
  get Summer() {
    return memoizedCreateIcon('Summer', SummerSvgPath, size24x24SvgProps);
  },
  get Sun() {
    return memoizedCreateIcon('Sun', SunSvgPath, size24x24SvgProps);
  },
  get Trash() {
    return memoizedCreateIcon('Trash', TrashSvgPath, size24x24SvgProps);
  },
  get UpArrow() {
    return memoizedCreateIcon('UpArrow', UpArrowSvgPath, size24x24SvgProps);
  },
  get Winter() {
    return memoizedCreateIcon('Winter', WinterSvgPath, size24x24SvgProps);
  },
};
