import {
  Dispatch,
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useReducer,
} from 'react';

import { RootActionMap, State, defaultState, rootReducer } from './reducer';

const UserProductContext = createContext<{
  state: State;
  dispatch: Dispatch<RootActionMap>;
}>({ state: defaultState, dispatch: () => null });

export const UserProductProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(rootReducer, defaultState);

  return (
    <UserProductContext.Provider value={{ state, dispatch }}>
      {children}
    </UserProductContext.Provider>
  );
};

export const useUserProductState = () => {
  return useContext(UserProductContext);
};
