import { queryClient } from '@modules/query-client';

export interface WaitType {
  type: 'DECORATION' | 'PROJECT' | 'NONE';
  projectId?: string;
  decoratorId?: string;
}

export interface State {
  wait: WaitType;
  season: string;
  time: string;
}

export const defaultState: State = {
  wait: { type: 'NONE' },
  season: '',
  time: '',
};

export type RootActionMap =
  | {
      type: 'CompleteWaiting';
    }
  | {
      type: 'WaitProject';
      payload: string;
    }
  | {
      type: 'WaitDecoration';
      payload: {
        projectId: string;
        decoratorId: string;
      };
    }
  | {
      type: 'SetTime';
      payload: string;
    }
  | {
      type: 'SetSeason';
      payload: string;
    }
  | {
      type: 'ClearDecoration';
    };

export const rootReducer = (state: State, action: RootActionMap): State => {
  switch (action.type) {
    case 'CompleteWaiting':
      if (state.wait.type === 'NONE') {
        return state;
      }
      queryClient.invalidateQueries();
      return {
        ...state,
        wait: {
          type: 'NONE',
        },
      };
    case 'WaitProject':
      return {
        ...state,
        wait: {
          type: 'PROJECT',
          projectId: action.payload,
        },
      };
    case 'WaitDecoration':
      return {
        ...state,
        wait: {
          type: 'DECORATION',
          ...action.payload,
        },
      };
    case 'SetTime':
      return {
        ...state,
        time: action.payload,
      };
    case 'SetSeason':
      return {
        ...state,
        season: action.payload,
      };
    case 'ClearDecoration':
      return {
        ...state,
        time: '',
        season: '',
      };
  }
  return state;
};
