import { ApiUrls } from '@modules/constants';

import { adminAxios } from '../admin-axios';
import { ProductResponseDto, VisualType } from './types';

interface CreateProductSuccessResponseData extends ProductResponseDto {}

interface CreateProductRequestParams {
  name: string;
  sku: string;
  scale: string;
  categoryId: string;
  subCategoryId: string;
  color?: string;
  visualType: VisualType;
}

export const createProduct = ({
  name,
  sku,
  scale,
  color,
  visualType,
  categoryId,
  subCategoryId,
}: CreateProductRequestParams) => {
  return adminAxios.post<CreateProductSuccessResponseData>(
    ApiUrls.ADMIN_PRODUCTS,
    {
      name,
      sku,
      scale,
      visualType,
      ...(visualType ? { color } : {}),
      category: categoryId,
      subCategory: subCategoryId,
    }
  );
};
