import { FC } from 'react';
import { useController } from 'react-hook-form';

import { TextField } from '@mui/material';

import { FormValues } from '../types';

export const NameTextField: FC = () => {
  const {
    field: { value, onChange },
  } = useController<FormValues>({
    name: 'name',
    rules: {
      required: true,
    },
  });

  return (
    <TextField
      sx={{
        width: 320,
      }}
      placeholder="Название комбинации"
      value={value}
      onChange={onChange}
    />
  );
};
