import { ApiUrls } from '@modules/constants';

import { adminAxios } from '../admin-axios';

interface EditCombinationSuccessResponseData {
  name: string;
  description: string;
  isVisible: boolean;
  id: string;
}

interface EditCombinationRequestParams {
  id: string;
  name: string;
  description: string;
  isVisible: boolean;
}

export const editCombination = ({
  id,
  ...data
}: EditCombinationRequestParams) => {
  return adminAxios.put<EditCombinationSuccessResponseData>(
    ApiUrls.ADMIN_COMBINATION_BY_ID(id),
    data
  );
};
