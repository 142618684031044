import { memo } from 'react';

import { VisualType } from '@adapters/admin/products';

import {
  Article,
  Description,
  Image,
  InfoBlock,
  ItemContainer,
  ItemContent,
  Label,
  Preview,
} from './specification-item.styles';

export interface SpecificationItemProps {
  id: string;
  label: string;
  description: string;
  visualType?: VisualType;
  imgUrl: string;
  color: string;
  article: string;
  isVisible: boolean;
}

export const SpecificationItem = memo<SpecificationItemProps>(
  ({ label, description, visualType, imgUrl, color, article, isVisible }) => {
    if (!isVisible) {
      return <></>;
    }

    let vType = VisualType.IMAGE;
    if (color) vType = VisualType.COLOR;
    if (visualType) vType = visualType;

    return (
      <ItemContainer>
        <ItemContent>
          <InfoBlock>
            <Label>{label}</Label>
            <Description>{description}</Description>
          </InfoBlock>

          <Preview>
            {vType === VisualType.IMAGE && <Image src={imgUrl} />}
            {vType === VisualType.COLOR && (
              <svg
                width="100%"
                height="64px"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="100%" height="64px" fill={color} />
              </svg>
            )}
          </Preview>

          <Article>{article}</Article>
        </ItemContent>
      </ItemContainer>
    );
  }
);
