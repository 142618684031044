import { FC, useCallback, useId, useMemo } from 'react';
import { useController } from 'react-hook-form';

import { COMBINATION_PARTS, PART_LIST } from '@modules/constants';
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import Icons from '@ui-kit/icons';

import { FormValues } from '../types';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const PartAutocomplete: FC = () => {
  const id = useId();
  const {
    field: { value, onChange },
  } = useController<FormValues>({
    name: 'parts',
  });

  const parts = useMemo(
    () => value?.split(',').filter((v) => !!v) || [],
    [value]
  );

  const handleChange = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      let value: string[] = [];

      if (typeof event.target.value === 'string') {
        value = event.target.value.split(',');
      } else {
        value = event.target.value || [];
      }

      onChange(value.filter((v) => !!v).join(',') || '');
    },
    [onChange]
  );

  const handleClear = useCallback(() => {
    onChange('');
  }, [onChange]);

  return (
    <FormControl fullWidth>
      <InputLabel id={id}>Тип комбинации</InputLabel>
      <Select
        labelId={id}
        value={parts}
        onChange={handleChange}
        multiple
        input={
          <OutlinedInput
            label="Тип комбинации"
            endAdornment={
              !!parts.length && (
                <IconButton onClick={handleClear} sx={{ marginRight: '8px' }}>
                  <Icons.Clear size={20} />
                </IconButton>
              )
            }
          />
        }
        MenuProps={MenuProps}
      >
        {PART_LIST.map((type) => (
          <MenuItem key={type} value={type}>
            {COMBINATION_PARTS[type]?.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
