import { Box, styled } from '@mui/material';

export const ItemContainer = styled(Box)`
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.07);
  height: 270px;
  width: 100%;
  @media print {
    height: 138px;
  }
`;
export const ItemContent = styled(Box)`
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  @media print {
    gap: 8px;
  }
`;

export const InfoBlock = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  flex-shrink: 0;

  @media print {
    gap: 4px;
  }
`;

export const Label = styled(Box)`
  color: #a50023;
  height: 44px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  text-overflow: ellipsis;
  overflow: hidden;
  line-clamp: 2;
  flex-shrink: 0;

  @media print {
    height: 22.5px;
    font-size: 8.151px;
    font-weight: 600;
    line-height: 11.207px;
  }
`;

export const Description = styled(Box)`
  color: #231f20;
  height: 72px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
  line-clamp: 4;
  flex-shrink: 0;

  @media print {
    height: 36.5px;
    font-size: 7.132px;
    font-weight: 400;
    line-height: 9.17px;
  }
`;

export const Preview = styled(Box)`
  height: 64px;
  width: 100%;
  overflow: hidden;
  border-radius: 4px;
  border: solid 1px gray;
  flex-shrink: 0;

  @media print {
    height: 32px;
  }
`;

export const Image = styled('img')`
  height: 100%;
  width: 100%;
  overflow: hidden;
  object-fit: cover;
`;

export const Article = styled(Box)`
  color: #8b8989;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  height: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-shrink: 0;

  @media print {
    height: 10px;
    font-size: 7.132px;
    font-style: normal;
    font-weight: 400;
    line-height: 9.17px;
  }
`;
