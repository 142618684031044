import { ApiUrls } from '@modules/constants';

import { userAxios } from '../user-axios';

interface RegenerateSuccessResponseData {
  id: string;
}

export const regenerateProject = () => {
  return userAxios.post<RegenerateSuccessResponseData>(
    ApiUrls.USER_REGENERATE_PROJECT
  );
};
