import { useSnackbar } from 'notistack';
import { memo } from 'react';
import { useMutation } from 'react-query';

import { deleteCategory } from '@adapters/admin/categories';
import { queryClient } from '@modules/query-client';
import { DeleteDialog } from '@ui-kit/delete-dialog';

interface DeleteCategoryDialogProps {
  onClose: () => void;
  categoryId?: string;
}

export const DeleteCategoryDialog = memo<DeleteCategoryDialogProps>(
  ({ onClose, categoryId }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { mutate: mutateDeleteCategory } = useMutation(deleteCategory, {
      onSuccess: () => {
        enqueueSnackbar('Категория успешно удалена', {
          variant: 'success',
        });
        queryClient.invalidateQueries();
        onClose();
      },
      onError: () => {
        enqueueSnackbar('Произошла ошибка', {
          variant: 'error',
        });
        queryClient.invalidateQueries();
        onClose();
      },
    });
    if (!categoryId) {
      return <></>;
    }
    return (
      <DeleteDialog
        title="Удалить Категорию?"
        subtitle="Категорию нельзя будет восстановить."
        open={!!categoryId}
        onClose={onClose}
        onConfirm={() => {
          mutateDeleteCategory({ id: categoryId });
        }}
      />
    );
  }
);
