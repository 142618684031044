import { ApiUrls } from '@modules/constants';

import { userAxios } from '../user-axios';

interface CreateProductSuccessResponseData {}

export interface CreateProjectRequestParams {
  file: File;
}

export const createProject = ({ file }: CreateProjectRequestParams) => {
  const formData = new FormData();

  formData.append('file', file);

  return userAxios.post<CreateProductSuccessResponseData>(
    ApiUrls.USER_PROJECTS,
    formData
  );
};
