import { useSnackbar } from 'notistack';
import { memo, useRef } from 'react';
import { useMutation, useQuery } from 'react-query';

import {
  VisualType,
  editProduct,
  getProduct,
  uploadImage,
} from '@adapters/admin/products';
import { queryClient } from '@modules/query-client';
import { CircularProgress, Stack } from '@mui/material';
import { Dialog } from '@ui-kit/dialog';
import { SelectedFile } from '@ui-kit/file-picker';

import { ProductForm } from './components/product-form';

interface ProductFormDialogProps {
  productId?: string;
  onClose: () => void;
  open: boolean;
}

export const EditProductDialog = memo<ProductFormDialogProps>((props) => {
  const { onClose, open = true, productId } = props;

  const { enqueueSnackbar } = useSnackbar();
  const selectedFile = useRef<SelectedFile | undefined>(undefined);

  const { mutate: uploadImageMutate } = useMutation(uploadImage, {
    onSuccess: () => {
      enqueueSnackbar('Продукт успешно изменен', {
        variant: 'success',
      });
      queryClient.invalidateQueries();
      onClose();
    },
    onError: () => {
      enqueueSnackbar('Продукт успешно изменен', {
        variant: 'success',
      });
      queryClient.invalidateQueries();
      enqueueSnackbar('Не удалось загрузить картинку', {
        variant: 'error',
      });
      onClose();
    },
  });

  const { mutate: editProductMutate } = useMutation(editProduct, {
    onSuccess: (data) => {
      if (selectedFile.current?.file) {
        uploadImageMutate({
          file: selectedFile.current.file,
          productId: data.data.id,
        });
        return;
      }
      enqueueSnackbar('Продукт успешно изменен', {
        variant: 'success',
      });

      queryClient.invalidateQueries();
      onClose();
    },
    onError: () => {
      enqueueSnackbar('Произошла ошибка', {
        variant: 'error',
      });
    },
  });

  const { data, isLoading } = useQuery(
    ['product', productId],
    () => {
      if (productId) {
        return getProduct({
          productId,
        });
      }
    },
    {
      cacheTime: 0,
      onError: () => {
        enqueueSnackbar('Произошла ошибка', {
          variant: 'error',
        });
        onClose();
      },
    }
  );
  const productData = data?.data;

  if (!productId) {
    return <></>;
  }

  if (isLoading) {
    /*TODO Skeleton*/
    return (
      <Dialog title="Изменить продукцию" open={open} onClose={onClose}>
        <Stack
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="350px"
        >
          <CircularProgress />
        </Stack>
      </Dialog>
    );
  }

  return (
    <Dialog title="Изменить продукцию" open={open} onClose={onClose}>
      <ProductForm
        defaultForm={{
          name: productData?.name || '',
          categoryId: productData?.category?.id || '',
          subcategoryId: productData?.subCategory?.id || '',
          vendorCode: productData?.sku || '',
          scale: productData?.scale || '',
          color: productData?.color || '',
          visualType: productData?.visualType || VisualType.IMAGE,
          imgUrl: productData?.media.url || '',
        }}
        onSubmit={(data, file) => {
          selectedFile.current =
            data.visualType === VisualType.IMAGE ? file : undefined;
          editProductMutate({
            productId,
            name: data.name,
            categoryId: data.categoryId,
            subCategoryId: data.subcategoryId,
            sku: data.vendorCode,
            scale: data.scale,
            visualType: data.visualType,
            color: data.visualType === VisualType.COLOR ? data.color : '',
          });
        }}
      />
    </Dialog>
  );
});
