import { memo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Button, Stack, TextField } from '@mui/material';

import { PartAutocomplete } from '../part-autocomplete';
import { FormValues } from '../types';

interface CategoryFormProps {
  onSubmit: (data: FormValues) => void;
  isEdit?: boolean;
  defaultValues?: FormValues;
}

export const CategoryForm = memo<CategoryFormProps>((props) => {
  const { onSubmit, defaultValues, isEdit } = props;
  const methods = useForm<FormValues>({ defaultValues });
  const { formState, register, handleSubmit: formSubmit } = methods;

  const handleSubmit = (data: FormValues) => {
    onSubmit(data);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={formSubmit(handleSubmit)}>
        <Stack direction="column" spacing={2}>
          <TextField
            {...register('name', { required: true, minLength: 2 })}
            placeholder="Введите название категории"
            label="Название категории"
          />
          <Stack direction="row">
            <PartAutocomplete />
          </Stack>

          <Stack direction="row" justifyContent="flex-end">
            <Button
              type="submit"
              disabled={!formState.isValid}
              variant="contained"
              size="small"
            >
              {isEdit ? 'Изменить' : 'Добавить'}
            </Button>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
});
