import axios from 'axios';

import { BASE_API } from '@modules/constants';
import { AppStorage } from '@modules/storage';

export const userAxios = axios.create();

userAxios.interceptors.request.use(
  (config) => {
    const accessToken = AppStorage.get('USER_AUTH').value?.accessToken;
    config.baseURL = BASE_API;
    if (accessToken) {
      config.headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

userAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if ([401, 403].includes(error.response.status)) {
      AppStorage.remove('USER_AUTH');
    }
    return Promise.reject(error);
  }
);
