import { FC, useCallback, useState } from 'react';

import { LoadPhoto } from '@widgets/load-photo';
import { RequestCostCalculationDialog } from '@widgets/request-cost-calculation-dialog';
import { UserProductWidget } from '@widgets/user-product-widget';
import { UserProductProvider } from '@widgets/user-product-widget/state';

export const UserProductPage: FC = () => {
  const [quoteProjectId, setQuoteProjectId] = useState('');
  const [isEmpty, setIsEmpty] = useState(false);

  const handleLoaded = useCallback(() => {
    setIsEmpty(false);
  }, []);
  const handleEmpty = useCallback(() => {
    setIsEmpty(true);
  }, []);

  if (isEmpty) {
    return <LoadPhoto onLoaded={handleLoaded} />;
  }

  return (
    <>
      <UserProductProvider>
        <UserProductWidget
          onRequestCostCalculation={setQuoteProjectId}
          onEmpty={handleEmpty}
        />
        <RequestCostCalculationDialog
          onClose={() => setQuoteProjectId('')}
          quoteProjectId={quoteProjectId}
        />
      </UserProductProvider>
    </>
  );
};
