import { FC, PropsWithChildren, useEffect } from 'react';
import { useQuery } from 'react-query';

import { login, testUser } from '@adapters/user';
import { AppStorage, AppStorageEvents } from '@modules/storage';
import { Alert, CircularProgress } from '@mui/material';

export const UserAreaWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { isLoading, isError, refetch } = useQuery('test-user', testUser);

  useEffect(() => {
    return AppStorageEvents.subscribe(({ key }) => {
      if (key === 'USER_AUTH') {
        refetch();
      }
    });
  }, [refetch]);

  if (isError) {
    return <UserLogin />;
  }

  if (isLoading) {
    return <CircularProgress />;
  }

  return <>{children}</>;
};

const UserLogin: FC = () => {
  const { data, isSuccess, isLoading, isError } = useQuery('user-login', login);

  useEffect(() => {
    if (isSuccess && data?.data) {
      AppStorage.set({
        key: 'USER_AUTH',
        value: {
          accessToken: data.data.accessToken,
        },
      });
    }
  }, [isSuccess, data]);

  if (isError) {
    return (
      <Alert severity="error">Произошла ошибка при получении данных</Alert>
    );
  }

  if (isLoading) {
    return <CircularProgress />;
  }

  return <></>;
};
