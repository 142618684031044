import React, { FC } from 'react';

import {  Stack, Typography } from '@mui/material';

import { NameTextField } from './name-text-field';
import { VisibleField } from './visible-field';

export const FormHeader: FC = () => {
  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Typography variant="subtitle1">Комбинация</Typography>
        <NameTextField />
        <VisibleField />
      </Stack>
    </Stack>
  );
};
