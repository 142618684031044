import { useCallback } from 'react';
import { useMutation } from 'react-query';

import { deleteUserDecoratorById } from '@adapters/user/project';

import { useUserProductState } from '../state';

export const useDeleteDecoration = () => {
  const {
    state: {
      wait: { decoratorId, projectId },
    },
    dispatch,
  } = useUserProductState();

  const handleComplete = useCallback(() => {
    dispatch({ type: 'CompleteWaiting' });
    dispatch({ type: 'ClearDecoration' });
  }, [dispatch]);

  const { mutate: deleteDecorator } = useMutation(deleteUserDecoratorById, {
    onSuccess: handleComplete,
    onError: handleComplete,
  });

  return useCallback(() => {
    decoratorId && projectId && deleteDecorator({ projectId, decoratorId });
  }, [decoratorId, projectId, deleteDecorator]);
};
