import { memo } from 'react';
import { Link } from 'react-router-dom';

import { Search } from '@mui/icons-material';
import {
  Box,
  Breadcrumbs,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material';

import { useContentHeader } from './content-header-context';
import { TitleHeader } from './title-header';

interface BreadcrumbsItem {
  label: string;
  to: string;
}

interface ContentHeaderProps {
  defaultTitle: string;
  onCreateClick: () => void;
  breadcrumbs?: BreadcrumbsItem[];
}

export const ContentHeader = memo<ContentHeaderProps>((props) => {
  const { defaultTitle, onCreateClick, breadcrumbs } = props;
  const { search, changeSearch } = useContentHeader();

  return (
    <Box>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        {breadcrumbs ? (
          <Breadcrumbs>
            {breadcrumbs.map(({ to, label }) => (
              <Link color="inherit" to={to}>
                {label}
              </Link>
            ))}
          </Breadcrumbs>
        ) : (
          <TitleHeader
            defaultTitle={defaultTitle}
            onCreateClick={onCreateClick}
          />
        )}

        <TextField
          placeholder="Поиск"
          value={search || ''}
          onChange={(e) => {
            changeSearch(e.currentTarget.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {}}
                  edge="end"
                >
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      {breadcrumbs && (
        <TitleHeader
          defaultTitle={defaultTitle}
          onCreateClick={onCreateClick}
        />
      )}
    </Box>
  );
});
