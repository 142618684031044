import { ApiUrls } from '@modules/constants';

import { adminAxios } from '../admin-axios';

interface CreateCombinationSuccessResponseData {
  name: string;
  description: string;
  isVisible: boolean;
  id: string;
}

interface CreateCombinationRequestParams {
  name: string;
  description: string;
  isVisible: boolean;
}

export const createCombination = (data: CreateCombinationRequestParams) => {
  return adminAxios.post<CreateCombinationSuccessResponseData>(
    ApiUrls.ADMIN_COMBINATIONS,
    data
  );
};
