import { FC, useState } from 'react';

import { Paper, Stack } from '@mui/material';
import { ContentHeader } from '@widgets/content-header';
import { ContentHeaderProvider } from '@widgets/content-header/content-header-context';
import { CreateProductDialog } from '@widgets/product-form-dialog';
import { ProductsWidget } from '@widgets/products-widget';

export const ProductsList: FC = () => {
  const [showForm, setShowForm] = useState(false);

  const toggleShowForm = () => setShowForm((value) => !value);

  return (
    <ContentHeaderProvider>
      <Paper sx={{ height: '100%' }}>
        <Stack sx={{ height: '100%' }} spacing={2}>
          <ContentHeader
            defaultTitle="Продукция"
            onCreateClick={toggleShowForm}
          />
          <ProductsWidget />
        </Stack>

        <CreateProductDialog onClose={toggleShowForm} open={showForm} />
      </Paper>
    </ContentHeaderProvider>
  );
};
