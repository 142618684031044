import { ApiUrls, CombinationPartType } from '@modules/constants';

import { adminAxios } from '../admin-axios';

interface CreateCombinationPartSuccessResponseData {
  id: string;
  part: CombinationPartType;
  isVisible: boolean;
  product: {
    id: string;
    name: string;
  };
}

interface CreatePartCombinationRequestParams {
  part: CombinationPartType;
  productId: string;
  combinationId: string;
  isVisible: boolean;
}

export const createPart = ({
  part,
  productId,
  combinationId,
  isVisible,
}: CreatePartCombinationRequestParams) => {
  return adminAxios.post<CreateCombinationPartSuccessResponseData>(
    ApiUrls.ADMIN_PARTS(combinationId),
    {
      part,
      product: productId,
      combination: combinationId,
      isVisible,
    }
  );
};
