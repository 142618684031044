import { MuiTableCellType } from './table.types';

export const MuiTableCell: MuiTableCellType = {
  styleOverrides: {
    root: {
      border: 0,
      paddingTop: 0,
      paddingBottom: 0,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    head: {
      color: '#231F20',
      fontFamily: 'Open Sans',
      fontSize: 14,
      fontWeight: 600,
      backgroundColor: '#f6f6f6',
      height: 48,
      ':first-of-type': {
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
      },
      ':last-child': {
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
      },
    },
    body: {
      height: 56,
      borderBottom: `1px solid #D0CFCF`,
      color: '#231F20',
      fontFamily: 'Open Sans',
      fontSize: 14,
      fontWeight: 400,
    },
  },
};
