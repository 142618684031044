export default (
  <>
    <g clipPath="url(#clip0_1875_2026)">
      <path
        d="M83.9333 2.2998H12.0667C6.39653 2.2998 1.79999 6.89635 1.79999 12.5665V84.4331C1.79999 90.1033 6.39653 94.6998 12.0667 94.6998H83.9333C89.6035 94.6998 94.2 90.1033 94.2 84.4331V12.5665C94.2 6.89635 89.6035 2.2998 83.9333 2.2998Z"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.5997 43.3663C38.2698 43.3663 42.8663 38.7698 42.8663 33.0997C42.8663 27.4296 38.2698 22.833 32.5997 22.833C26.9296 22.833 22.333 27.4296 22.333 33.0997C22.333 38.7698 26.9296 43.3663 32.5997 43.3663Z"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.2 63.8997L78.3585 48.0582C76.4333 46.1335 73.8224 45.0522 71.1 45.0522C68.3777 45.0522 65.7668 46.1335 63.8415 48.0582L17.2 94.6997"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1875_2026">
        <rect
          width="96"
          height="96"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </>
);
