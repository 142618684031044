import { FC, useCallback } from 'react';
import { ColorPicker, ColorService, IColor } from 'react-color-palette';
import 'react-color-palette/css';
import { useController } from 'react-hook-form';

import { FormValues } from '../types';

export const ColorPalette: FC = () => {
  const {
    field: { value, onChange },
  } = useController<FormValues>({
    name: 'color',
    rules: {
      required: true,
    },
  });

  const handleChange = useCallback(
    (color: IColor) => {
      onChange(color.hex);
    },
    [onChange]
  );

  return (
    <ColorPicker
      hideAlpha
      color={ColorService.convert('hex', value || '#000000')}
      onChange={handleChange}
    />
  );
};
