import { useSnackbar } from 'notistack';
import { memo } from 'react';
import { useMutation, useQuery } from 'react-query';

import { getCategory } from '@adapters/admin/categories';
import { editCategory } from '@adapters/admin/categories';
import { CombinationPartType } from '@modules/constants';
import { queryClient } from '@modules/query-client';
import { Dialog } from '@ui-kit/dialog';

import { CategoryForm } from './components/category-form';

interface SubcategoryFormDialogProps {
  onClose: () => void;
  onSubmit?: () => void;
  open: boolean;
  categoryId: string;
}

export const EditCategoryDialog = memo<SubcategoryFormDialogProps>((props) => {
  const { onClose, open = true, onSubmit, categoryId } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { mutate } = useMutation(editCategory, {
    onSuccess: () => {
      enqueueSnackbar('Категория успешно изменена', {
        variant: 'success',
      });
      onSubmit && onSubmit();
      queryClient.invalidateQueries();
    },
    onError: () => {
      enqueueSnackbar('Произошла ошибка', {
        variant: 'error',
      });
    },
  });

  const { data } = useQuery(
    ['category', categoryId],
    () => {
      if (categoryId) {
        return getCategory({
          categoryId: categoryId || '',
        });
      }
    },
    {
      cacheTime: 0,
      onError: () => {
        enqueueSnackbar('Произошла ошибка', {
          variant: 'error',
        });
        onClose();
      },
    }
  );

  const categoriesData = data?.data;

  if (categoriesData) {
    return (
      <Dialog title="Редактировать категорию" open={open} onClose={onClose}>
        <CategoryForm
          defaultValues={{
            name: categoriesData.name,
            parts: categoriesData.parts?.join(',') || '',
          }}
          onSubmit={(data) => {
            onClose();
            categoryId &&
              mutate({
                categoryId,
                name: data.name,
                parts: data.parts.split(',') as CombinationPartType[],
              });
          }}
          isEdit
        />
      </Dialog>
    );
  }
  return <></>;
});
