import { memo, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { PART_LIST } from '@modules/constants';
import { Button, Grid, Stack } from '@mui/material';

import { FormValues } from '../types';
import { FormHeader } from './form-header';
import { ProductAutocomplete } from './product-autocomplete';

interface ProductFormProps {
  onSubmit: (data: FormValues) => void;
  defaultForm?: FormValues;
  isDisabled: boolean;
  categoriesMap: Map<string | undefined | null, string[]>;
}

export const CombinationForm = memo<ProductFormProps>((props) => {
  const { onSubmit, defaultForm, isDisabled, categoriesMap } = props;

  const methods = useForm<FormValues>({ defaultValues: defaultForm });
  const { formState, handleSubmit: formSubmit } = methods;

  const handleSubmit = useCallback(
    (data: FormValues) => {
      onSubmit(data);
    },
    [onSubmit]
  );

  return (
    <FormProvider {...methods}>
      <form style={{ height: '100%' }} onSubmit={formSubmit(handleSubmit)}>
        <Stack
          direction="column"
          sx={{ height: '100%' }}
          spacing={4}
          justifyContent="flex-start"
        >
          <Stack direction="column" spacing={4}>
            <FormHeader />

            <Grid container gap={2}>
              {PART_LIST.map((type) => (
                <ProductAutocomplete
                  categoriesMap={categoriesMap}
                  key={type}
                  type={type}
                />
              ))}
            </Grid>
          </Stack>

          <Stack direction="row" justifyContent="flex-end">
            <Button
              type="submit"
              disabled={!formState.isValid || isDisabled}
              variant="contained"
              size="small"
            >
              Сохранить
            </Button>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
});
