import { ApiUrls } from '@modules/constants';

import { adminAxios } from '../admin-axios';

interface GetMeSuccessResponseData {
  email: string;
  id: string;
  role: string;
}

export const getMe = () => {
  return adminAxios.get<GetMeSuccessResponseData>(ApiUrls.ADMIN_GET_ME);
};
