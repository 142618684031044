import { ApiUrls } from '@modules/constants';

import { adminAxios } from '../admin-axios';
import { SubcategoryResponseDto } from './types';

interface GetSubcategoriesSuccessResponseData {
  total: number;
  data: SubcategoryResponseDto[];
}

interface SubcategoriesRequestParams {
  categoryId: string;
  page: number;
  pageSize: number;
  search?: string;
}

export const getSubcategories = ({
  categoryId,
  page,
  pageSize,
  search,
}: SubcategoriesRequestParams) => {
  return adminAxios.get<GetSubcategoriesSuccessResponseData>(
    ApiUrls.ADMIN_SUBCATEGORIES(categoryId),
    {
      params: {
        page,
        pageSize,
        ...(search ? { search } : {}),
        sorts: 'name:asc',
      },
    }
  );
};
