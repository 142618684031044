import { ApiUrls } from '@modules/constants';

import { adminAxios } from '../admin-axios';
import { CombinationPartResponseDto, CombinationResponseDto } from './types';

export interface GetCombinationSuccessResponseData
  extends CombinationResponseDto {
  combinationParts: CombinationPartResponseDto[];
}

interface GetCombinationRequestParams {
  combinationId: string;
}

export const getCombination = ({
  combinationId,
}: GetCombinationRequestParams) => {
  return adminAxios.get<GetCombinationSuccessResponseData>(
    ApiUrls.ADMIN_COMBINATION_BY_ID(combinationId)
  );
};
