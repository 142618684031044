import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { getAllCategories } from '@adapters/admin/categories';

export interface UseCategoriesReturn {
  categoriesMap: Map<string | undefined | null, string[]>;
  isLoading: boolean;
  isError: boolean;
}

export const useCategories = (): UseCategoriesReturn => {
  const {
    data: categoriesData,
    isLoading,
    isError,
  } = useQuery(['all-categories'], () => getAllCategories());

  return useMemo<UseCategoriesReturn>(() => {
    const categoriesMap = new Map<string | undefined | null, string[]>();

    if (categoriesData) {
      const data = categoriesData.data;

      data.data.forEach((category) => {
        const { parts, id } = category;

        parts?.forEach((part) => {
          if (!categoriesMap.get(part)) {
            categoriesMap.set(part, []);
          }
          categoriesMap.get(part)?.push(id);
        });
      }, []);
    }

    return {
      categoriesMap,
      isLoading,
      isError,
    };
  }, [categoriesData, isLoading, isError]);
};
