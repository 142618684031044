import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { Root } from './login-layout.styles';

export const LoginLayout: FC = () => {
  return (
    <Root>
      <Outlet />
    </Root>
  );
};
