import { memo, useEffect, useState } from 'react';

import { Box } from '@mui/material';

// import { Decoration } from '../decoration';
import { Loader } from '../loader';
import { Image, ImgBlock } from './result.styles';

interface ResultProps {
  src?: string;
}

export const Result = memo<ResultProps>(({ src }) => {
  const [isLoad, setLoad] = useState(false);

  const isPending = !src || !isLoad;

  useEffect(() => {
    setLoad(false);
  }, [src]);

  return (
    <Box
      sx={{
        position: 'relative',
        borderRadius: 1,
        width: '100%',
        minHeight: '530px',
        overflow: 'hidden',
        boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.07)',
      }}
    >
      {/*<Decoration disabled={isPending} />*/}
      <ImgBlock>
        {isPending && <Loader />}
        <Image
          key={src}
          onLoad={() => setLoad(true)}
          style={
            isPending
              ? {
                  opacity: 0,
                  position: 'absolute',
                }
              : {}
          }
          src={src}
        />
      </ImgBlock>
    </Box>
  );
});
