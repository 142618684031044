import { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { getSubcategories } from '@adapters/admin/subcategories';
import {
  Alert,
  CircularProgress,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { CellControls, CellText } from '@ui-kit/table';
import { useHeaderContext } from '@widgets/content-header';
import { EditSubcategoryDialog } from '@widgets/subcategory-form-dialog';

import { DeleteSubcategoryDialog } from './conponents/delete-subcategory-dialog';
import { useCategory } from './hooks';

const ROWS_PER_PAGE = 40;

export const SubcategoryWidget: FC = () => {
  const [page, setPage] = useState(1);
  const title = useCategory();
  const { categoryId } = useParams();
  const [deleteId, setDeleteId] = useState('');
  const [editId, setEditId] = useState('');

  const { debouncedSearch } = useHeaderContext(title);

  const { data, isError, isLoading } = useQuery(
    ['subcategories', categoryId, page, debouncedSearch],
    () =>
      getSubcategories({
        categoryId: categoryId || '',
        page,
        pageSize: ROWS_PER_PAGE,
        search: debouncedSearch,
      })
  );

  const categoriesData = data?.data?.data || [];
  const pageTotal = Math.floor(
    (data?.data && data?.data?.total / ROWS_PER_PAGE + 1) || 1
  );

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (page < 1) {
      setPage(1);
    }
    if (page > pageTotal) {
      setPage(pageTotal);
    }
  }, [isLoading, page, pageTotal]);

  if (isError) {
    return (
      <Alert severity="error">Произошла ошибка при получении данных</Alert>
    );
  }

  if (isLoading) {
    return (
      <Stack
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100%"
      >
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <>
      <DeleteSubcategoryDialog
        categoryId={categoryId}
        subcategoryId={deleteId}
        onClose={() => {
          setDeleteId('');
        }}
      />
      <EditSubcategoryDialog
        subcategoryId={editId}
        onClose={() => setEditId('')}
        open={!!editId}
      />

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 48 }}>№</TableCell>
              <TableCell scope="row">Название</TableCell>
              <TableCell style={{ width: 104 }}>Опции</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categoriesData.map((subcategory, index) => (
              <TableRow key={subcategory.id}>
                <TableCell>{(index + 1) * page}</TableCell>
                <CellText>{subcategory.name}</CellText>
                <CellControls
                  onDelete={() => {
                    setDeleteId(subcategory.id);
                  }}
                  onEdit={() => {
                    setEditId(subcategory.id);
                  }}
                />
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          variant="outlined"
          count={pageTotal}
          page={page}
          onChange={(event, value) => {
            setPage(value);
          }}
          disabled={pageTotal < 2}
        />
      </TableContainer>
    </>
  );
};
