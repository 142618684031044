import { ApiUrls } from '@modules/constants';

import { adminAxios } from '../admin-axios';
import { ProductResponseDto } from './types';

interface GetProductSuccessResponseData extends ProductResponseDto {}

interface GetProductRequestParams {
  productId: string;
}

export const getProduct = ({ productId }: GetProductRequestParams) => {
  return adminAxios.get<GetProductSuccessResponseData>(
    ApiUrls.ADMIN_PRODUCT_BY_ID(productId)
  );
};
