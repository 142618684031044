import { ApiUrls } from '@modules/constants';

import { adminAxios } from '../admin-axios';
import { CategoryResponseDto } from './types';

interface GetCategoriesSuccessResponseData {
  total: number;
  data: CategoryResponseDto[];
}

interface CategoriesRequestParams {
  page: number;
  pageSize: number;
  search?: string;
}

export const getCategories = ({
  page,
  pageSize,
  search,
}: CategoriesRequestParams) => {
  return adminAxios.get<GetCategoriesSuccessResponseData>(
    ApiUrls.ADMIN_CATEGORIES,
    {
      params: {
        page,
        pageSize,
        ...(search ? { search } : {}),
        sorts: 'name:asc',
      },
    }
  );
};
