export default (
  <>
    <path
      d="M9.65576 8.25195L5.22461 18.4566C5.15144 18.6385 5.13336 18.8379 5.17262 19.03C5.21188 19.2221 5.30675 19.3984 5.44543 19.537C5.58411 19.6756 5.76048 19.7704 5.9526 19.8096C6.14472 19.8488 6.34412 19.8306 6.526 19.7573L16.7285 15.3213"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.5561 12.4922C20.4936 11.5543 21.0201 10.2824 21.0197 8.95636C21.0194 7.63028 20.4923 6.35863 19.5544 5.42117C18.6165 4.48371 17.3446 3.95723 16.0186 3.95754C14.6925 3.95786 13.4208 4.48494 12.4834 5.42284"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.5561 12.4923C19.9313 12.8673 20.1421 13.376 20.1423 13.9064C20.1424 14.4368 19.9318 14.9456 19.5568 15.3207C19.1818 15.6959 18.6732 15.9067 18.1427 15.9069C17.6123 15.907 17.1035 15.6964 16.7284 15.3214L9.65564 8.25201C9.28048 7.87702 9.06964 7.36837 9.06952 6.83793C9.06939 6.3075 9.27998 5.79874 9.65497 5.42358C10.03 5.04842 10.5386 4.83759 11.069 4.83746C11.5995 4.83733 12.1082 5.04793 12.4834 5.42291"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
