import { FC, PropsWithChildren, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Navigate, useNavigate } from 'react-router-dom';

import { getMe } from '@adapters/admin/auth';
import { Urls } from '@modules/constants';
import { AppStorageEvents } from '@modules/storage';
import { CircularProgress } from '@mui/material';

export const PrivateAreaWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { isLoading, isError } = useQuery('getMe', getMe, {});
  const navigate = useNavigate();

  useEffect(() => {
    return AppStorageEvents.subscribe(({ key, value }) => {
      if (key === 'ADMIN_AUTH' && !value?.accessToken) {
        navigate(Urls.ADMIN_LOGIN);
      }
    });
  }, [navigate]);

  if (isError) {
    return <Navigate to={Urls.ADMIN_LOGIN} replace />;
  }

  if (isLoading) {
    return <CircularProgress />;
  }

  return <>{children}</>;
};
