export const COMBINATION_PARTS = {
  ROOF_COVER: { label: 'Кровля: покрытие', defaultVisible: true },
  ROOF_END_FRAME: {
    label: 'Кровля: торцевое обрамление',
    defaultVisible: false,
  },
  ROOF_ENDOVA: { label: 'Кровля: Ендова', defaultVisible: false },
  ROOF_RIDGE: { label: 'Кровля: Конек', defaultVisible: false },
  ROOF_SNOW_HOLDERS: {
    label: 'Кровля: Снегозадержатели',
    defaultVisible: true,
  },
  ROOF_FLUE: { label: 'Кровля: Трубы дымохода', defaultVisible: false },
  DRAINAGE_SYSTEM_GUTTER: {
    label: 'Водосточная система: желоб',
    defaultVisible: false,
  },
  DRAINAGE_SYSTEM_FUNNEL: {
    label: 'Водосточная система: воронка желоба',
    defaultVisible: false,
  },
  DRAINAGE_SYSTEM_TUBE: {
    label: 'Водосточная система: труба',
    defaultVisible: false,
  },
  DRAINAGE_SYSTEM_WITHDRAWAL: {
    label: 'Водосточная система: отвод',
    defaultVisible: false,
  },
  SOFFIT: { label: 'Софит', defaultVisible: true },
  FRONT_COVER: { label: 'Фасад: покрытие', defaultVisible: true },
  FRONT_CORNERS: { label: 'Фасад: Обрамление углов', defaultVisible: false },
  FRONT_WINDOWS: { label: 'Фасад: Обрамление окон', defaultVisible: false },
  FRONT_DOORS: {
    label: 'Фасад: Обрамление дверей и проемов',
    defaultVisible: false,
  },
  FRONT_TIDES: { label: 'Фасад: отливы', defaultVisible: false },
  BASEMENT_COVER: { label: 'Цоколь: покрытие', defaultVisible: true },
  BASEMENT_CORNERS: { label: 'Цоколь: углы', defaultVisible: false },
};

export type CombinationPartType = keyof typeof COMBINATION_PARTS;

export const PART_LIST = Object.keys(
  COMBINATION_PARTS
) as CombinationPartType[];
