export interface ProductResponseDto {
  name: string;
  sku: string;
  scale: string;
  color: string;
  visualType: VisualType;
  category?: null | {
    name: string;
    id: string;
  };
  subCategory?: null | {
    name: string;
    category: string;
    id: string;
  };
  id: string;
  media: {
    url: string;
  };
}

export enum VisualType {
  IMAGE = 'image',
  COLOR = 'color',
}
