export default (
  <>
    <g id="Icon">
      <g>
        <path
          fill="currentColor"
          d="m9.5 19.561 5.303-5.304-1.06-1.06-5.304 5.303z"
        />
        <path
          fill="currentColor"
          d="m14.803 18.5-5.303-5.303-1.061 1.06 5.304 5.304z"
        />
        <path
          fill="currentColor"
          d="m8.396 17.129.75-.75-1.414-1.415c-.39-.39-.39-1.023 0-1.414l1.061-1.06c.39-.391 1.024-.391 1.414 0l1.414 1.414 1.415-1.414c.39-.391 1.023-.391 1.414 0l1.06 1.06c.391.391.391 1.024 0 1.414l-1.414 1.415.75.75h2.154c3.174 0 5.75-2.577 5.75-5.75 0-3.174-2.576-5.75-5.75-5.75-.31 0-.615.024-.912.072-.963-1.826-2.881-3.072-5.088-3.072-2.772 0-5.088 1.965-5.63 4.577-2.34.416-4.12 2.463-4.12 4.923 0 2.759 2.24 5 5 5z"
        />
      </g>
    </g>
  </>
);
