import { ApiUrls } from '@modules/constants';

import { userAxios } from '../user-axios';

type CreateDecorationSuccessResponseData = boolean;

interface EditProductRequestParams {
  projectId: string;
  time: string;
  season: string;
}

export const createDecoration = ({
  projectId,
  time,
  season,
}: EditProductRequestParams) => {
  return userAxios.post<CreateDecorationSuccessResponseData>(
    ApiUrls.USER_PROJECT_BY_ID(projectId),
    {
      timeOfDay: time || undefined,
      timeOfYear: season || undefined,
    }
  );
};
