import { Image } from '@ui-kit/image';
import { Box, IconButton, Stack } from '@mui/material';
import React, { memo } from 'react';
import { CombinationMediaResponseDto } from '@adapters/admin';
import { FilePicker, SelectedFile } from '@ui-kit/file-picker';
import {
  ActionIconContainer,
  CombinationImageItemContainer,
} from '@widgets/combination-widget/components/combination-media/combination-media.styles';
import Icons from '@ui-kit/icons';

interface CombinationMediaProps {
  media?: CombinationMediaResponseDto[];
  onFileChange?: (file: SelectedFile | undefined) => void;
  onFileDelete?: (id: string) => void;
}

export const CombinationMedia = memo<CombinationMediaProps>(({
  media, onFileChange, onFileDelete
                                      }) => {
  return (
    <Stack direction='row' alignItems='flex-start' spacing={2} marginTop={3} marginBottom={3}>
      <Stack direction='row' alignItems='flex-start' spacing={2} useFlexGap={true} flexGrow={1} flexWrap='wrap'>
        {media?.map(image => (
          <CombinationImageItemContainer
            key={image.id}
          >
            <Image url={image.url} key={image.id}/>
            <ActionIconContainer>
              <IconButton
                aria-label="delete"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  if (onFileDelete) {
                    onFileDelete(image.id);
                  }
                }}
              >
                <Icons.Trash size={24} />
              </IconButton>
            </ActionIconContainer>
          </CombinationImageItemContainer>
        ))}
      </Stack>
      <Box>
        <FilePicker onChange={onFileChange} isButton />
      </Box>
    </Stack>
  )
})
