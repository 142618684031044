import { FC } from 'react';

import { Urls } from '@modules/constants';
import { MenuList, Paper, Typography } from '@mui/material';
import Icons from '@ui-kit/icons';

import { MenuItem } from './components/menu-item';

const MENU_ITEMS = [
  {
    icon: Icons.Categories,
    label: 'Категории продукции',
    url: Urls.ADMIN_CATEGORIES,
  },
  {
    icon: Icons.Products,
    label: 'Продукция',
    url: Urls.ADMIN_PRODUCTS,
  },
  {
    icon: Icons.Combinations,
    label: 'Комбинации',
    url: Urls.ADMIN_COMBINATIONS,
  },
];

export const SideMenu: FC = () => {
  return (
    <Paper sx={{ height: '100%' }}>
      <Typography variant="subtitle2" mb={2}>
        Меню
      </Typography>

      <MenuList>
        {MENU_ITEMS.map(({ url, label, icon: Icon }) => (
          <MenuItem key={url} url={url}>
            <Icon size={24} />
            <span>{label}</span>
          </MenuItem>
        ))}
      </MenuList>
    </Paper>
  );
};
