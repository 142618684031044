import { memo } from 'react';

import { Image } from '@ui-kit/image';

import { Container, ItemContainer } from './tabs.styles';

export interface TabItem {
  id: string;
  url: string;
}

interface TabsProps {
  items: TabItem[];
  value: string;
  onChange: (id: string) => void;
}

export const Tabs = memo<TabsProps>((props) => {
  const { items, value, onChange } = props;

  return (
    <Container direction="row">
      {items.map(({ url, id }) =>
        id === value ? (
          <ItemContainer
            key={id}
            onClick={() => {
              onChange(id);
            }}
            sx={{
              padding: '2px',
              border: `1px solid #A50023`,
            }}
          >
            <Image url={url} />
          </ItemContainer>
        ) : (
          <ItemContainer
            key={id}
            onClick={() => {
              onChange(id);
            }}
          >
            <Image url={url} />
          </ItemContainer>
        )
      )}
    </Container>
  );
});
