import { MuiTableCell } from './table-cell.theme';
import { MuiTableContainer } from './table-container.theme';
import { MuiTable } from './table.theme';
import { TableType } from './table.types';

export const TableTheme: TableType = {
  MuiTable,
  MuiTableCell,
  MuiTableContainer,
};
