import { Box, Typography, styled } from '@mui/material';

export const Header = styled(Typography)`
  color: #000;

  font-size: 34px;
  font-style: normal;
  font-weight: 700;
`;

export const BoxHeader = styled(Box)`
  margin-bottom: 32px;
  @media print {
    display: none;
  }
`;
