import { useEffect, useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';

import { createDecoration, getProjectById } from '@adapters/user/project';
import { COMBINATION_PARTS, SEASON, TIMES_OF_DAY } from '@modules/constants';
import { queryClient } from '@modules/query-client';

import { SpecificationItemProps } from '../components/specification-item';
import { useUserProductState } from '../state';

export const useProjectById = (projectId: string) => {
  const {
    state: { season, time },
    dispatch,
  } = useUserProductState();
  const {
    data,
    isLoading: isProductLoading,
    isSuccess: isProductSuccess,
  } = useQuery(['getProjectById', projectId], () => {
    if (!projectId) {
      return undefined;
    }
    return getProjectById(projectId);
  });

  useEffect(() => {
    dispatch({ type: 'ClearDecoration' });
  }, [projectId, dispatch]);

  useEffect(() => {
    if (!data) {
      return;
    }
    if (!data?.data.distMedia.url) {
      dispatch({
        type: 'WaitProject',
        payload: data.data.id,
      });
      return;
    }
    const waitDecorator = data?.data.decorMedia.find((d) => !d.url);
    if (waitDecorator) {
      dispatch({
        type: 'WaitDecoration',
        payload: { projectId: data.data.id, decoratorId: waitDecorator.id },
      });
      return;
    }
    dispatch({
      type: 'CompleteWaiting',
    });
  }, [data, dispatch]);

  const { mutate: mutateDecoration, isLoading: isDecorationLoading } =
    useMutation(createDecoration, {
      onSuccess: (data) => {
        queryClient.invalidateQueries();
      },
    });

  const currentDecorator = useMemo(() => {
    if (!isProductSuccess || !data?.data) {
      return undefined;
    }

    const product = data.data;

    return product.decorMedia.find((d) => {
      const decorTime =
        TIMES_OF_DAY.find(({ id }) => d.decorators.includes(id))?.id || '';
      const decorSeason =
        SEASON.find(({ id }) => d.decorators.includes(id))?.id || '';

      return decorTime === time && decorSeason === season;
    });
  }, [data, isProductSuccess, season, time]);

  useEffect(() => {
    if (!isProductSuccess || !data?.data.distMedia.url || currentDecorator) {
      return;
    }
    if (season || time) {
      mutateDecoration({
        projectId,
        season,
        time,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [season, time]);

  return useMemo(() => {
    let resultUrl = '';
    let combinationName: string | undefined = undefined;
    let specifications: SpecificationItemProps[] = [];

    if (isProductSuccess && data?.data) {
      const product = data.data;

      specifications = product.combination.combinationParts.map((p) => ({
        id: p.id,
        label: COMBINATION_PARTS[p.part].label,
        description: p.product.name,
        imgUrl: p.product.media.url,
        article: `Артикул: ${p.product.sku || 'нет'}`,
        isVisible: p.isVisible,
        visualType: p.product.visualType,
        color: p.product.color,
      }));

      combinationName = product.combination.isVisible
        ? product.combination.name
        : undefined;

      if (currentDecorator) {
        resultUrl = currentDecorator?.url;
      } else {
        resultUrl = product.distMedia.url;
      }
    }

    return {
      resultUrl,
      combinationName,
      specifications,
      isPending: isProductLoading || isDecorationLoading,
    };
  }, [
    data,
    isProductSuccess,
    currentDecorator,
    isDecorationLoading,
    isProductLoading,
  ]);
};
