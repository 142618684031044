import { AppStoragePayload, StorageListener } from './app-storage-types';

export const OnAppStorageChangeName = 'OnAppStorageChange';

export const AppStorageEvents = {
  emit: (payload: AppStoragePayload) => {
    const event = new CustomEvent(OnAppStorageChangeName, {
      detail: payload,
    });
    window.dispatchEvent(event);
  },

  subscribe: (listener: StorageListener) => {
    const eventListener = (event: CustomEvent<AppStoragePayload>) => {
      listener(event.detail);
    };

    window.addEventListener(
      OnAppStorageChangeName,
      eventListener as EventListener
    );

    // return unsubscribe
    return () => {
      window.removeEventListener(
        OnAppStorageChangeName,
        eventListener as EventListener
      );
    };
  },
};
