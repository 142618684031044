import { memo } from 'react';

import { VisualType } from '@adapters/admin/products';
import Icons from '@ui-kit/icons';

import { ColorBlock, ErrorBlock, TableCell } from './cell-img.style';
import { Img } from './cell-img.style';

interface TableControlsProps {
  visualType: VisualType;
  src?: string;
  color: string;
}

export const CellImg = memo<TableControlsProps>(
  ({ visualType, src, color }) => {
    if (visualType === VisualType.COLOR) {
      return (
        <TableCell>
          <ColorBlock style={{ backgroundColor: color }} />
        </TableCell>
      );
    }
    if (!src) {
      return (
        <TableCell>
          <ErrorBlock>
            <Icons.LoadError size={24} />
          </ErrorBlock>
        </TableCell>
      );
    }
    return (
      <TableCell>
        <Img src={src} />
      </TableCell>
    );
  }
);
