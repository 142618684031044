import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { Box, Stack } from '@mui/material';

import { BoxHeader, Header } from './main-layout.styles';

export const MainLayout: FC = () => {
  return (
    <Stack direction="column" spacing={4} width="100%" gap={4}>
      <Stack width="100%" alignItems="center" justifyContent="center">
        <Box width="100%" maxWidth={1150}>
          <BoxHeader width="100%">
            <Header>Визуализатор Красивого дома</Header>
          </BoxHeader>
          <Box>
            <Outlet />
          </Box>
        </Box>
      </Stack>
    </Stack>
  );
};
