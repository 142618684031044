import { memo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Box, Button, Grid, Stack, TextField, Typography } from '@mui/material';
import Icons from '@ui-kit/icons';

import { FormValues } from '../types';

interface RequestCostCalculationFormProps {
  disabled: boolean;
  combinationName: string;
  onSubmit: (data: FormValues) => void;
}

export const RequestCostCalculationForm = memo<RequestCostCalculationFormProps>(
  (props) => {
    const { onSubmit, disabled, combinationName } = props;

    const methods = useForm<FormValues>();
    const { formState, register, handleSubmit: formSubmit } = methods;

    const handleSubmit = (data: FormValues) => {
      onSubmit(data);
    };

    return (
      <FormProvider {...methods}>
        <form onSubmit={formSubmit(handleSubmit)}>
          <Stack direction="column" spacing={3}>
            <Stack direction="column" spacing={2}>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <TextField
                      fullWidth
                      {...register('fullName', {
                        required: true,
                        minLength: 2,
                      })}
                      label="Ваше имя"
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <TextField
                      fullWidth
                      {...register('city', { required: true, minLength: 2 })}
                      label="Город"
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <TextField
                      fullWidth
                      type="email"
                      {...register('email', { required: true, minLength: 2 })}
                      label="Адрес электронной почты"
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <TextField
                      fullWidth
                      type="phone"
                      {...register('phone', { required: true, minLength: 2 })}
                      label="Номер телефона"
                      placeholder="+79612345654"
                    />
                  </Grid>
                </Grid>
              </Box>
              <TextField {...register('notes')} label="Комментарий" />
            </Stack>

            <Stack
              direction="row"
              width="100%"
              gap={2}
              justifyContent="space-between"
            >
              <Stack
                color="#8B8989"
                direction="row"
                padding="0px 24px 0px 16px"
                alignItems="center"
                justifyContent="center"
                borderRadius="24px"
                sx={{
                  flexShrink: 1,
                  backgroundColor: '#F6F6F6',
                  maxWidth: '480px',
                }}
                gap={2}
              >
                <Icons.Pin style={{ flexShrink: 0 }} size={24} />
                <Typography
                  color="#231F20"
                  variant="body1"
                  sx={{
                    flexShrink: 1,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {combinationName}
                </Typography>
              </Stack>
              <Button
                sx={{ flexShrink: 0 }}
                type="submit"
                disabled={!formState.isValid || disabled}
                variant="contained"
                size="small"
              >
                Отправить
              </Button>
            </Stack>
          </Stack>
        </form>
      </FormProvider>
    );
  }
);
