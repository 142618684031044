import { memo } from 'react';

import { DeleteDialog } from '@ui-kit/delete-dialog';

interface BackDialogDialogProps {
  onClose: () => void;
  onDelete: () => void;
  isVisible?: boolean;
}

export const BackDialog = memo<BackDialogDialogProps>(
  ({ onClose, onDelete, isVisible }) => {
    return (
      <DeleteDialog
        title="Ничего не понравилось?"
        subtitle="Возможно нужно немного времени на вдохновение. Начните сначала или вернитесь назад, чтобы выбрать из готовых вариантов"
        cancelText="остаться"
        confirmText="начать сначала"
        open={!!isVisible}
        onClose={onClose}
        onConfirm={onDelete}
      />
    );
  }
);
