import { CombinationPartType } from '@modules/constants';

import { createPart } from './create-part';
import { editPart } from './edit-part';

interface SavePartCombinationRequestParams {
  id?: string;
  part: CombinationPartType;
  productId: string;
  combinationId: string;
  isVisible: boolean;
}

export const savePart = ({
  id,
  ...props
}: SavePartCombinationRequestParams) => {
  if (id) {
    editPart({
      id,
      ...props,
    });
    return;
  }
  return createPart(props);
};
