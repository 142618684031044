import { ApiUrls } from '@modules/constants';

import { userAxios } from '../user-axios';

type DeleteUserProjectByIdSuccessResponseData = boolean;

export const deleteUserProjectById = (projectId: string) => {
  return userAxios.delete<DeleteUserProjectByIdSuccessResponseData>(
    ApiUrls.USER_PROJECT_BY_ID(projectId)
  );
};
