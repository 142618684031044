import { useEffect } from 'react';

import { useContentHeader } from './content-header-context';

export const useHeaderContext = (title?: string) => {
  const context = useContentHeader();

  useEffect(() => {
    if (context.title !== title) {
      context.changeTitle(title || '');
    }
  }, [context, context.title, title]);

  return context;
};
