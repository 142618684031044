import { enqueueSnackbar } from 'notistack';
import { memo, useCallback } from 'react';
import { useMutation, useQuery } from 'react-query';

import { getProjectById } from '@adapters/user';
import { quoteProject } from '@adapters/user/project/quote-project';
import { Dialog } from '@ui-kit/dialog';

import { RequestCostCalculationForm } from './components/request-cost-calculation-form';
import { FormValues } from './components/types';

interface RequestCostCalculationDialogProps {
  onClose: () => void;
  quoteProjectId: string;
}

export const RequestCostCalculationDialog =
  memo<RequestCostCalculationDialogProps>((props) => {
    const { onClose, quoteProjectId } = props;

    const { data: projectData } = useQuery(
      ['getProductById', quoteProjectId],
      () => {
        return quoteProjectId ? getProjectById(quoteProjectId) : undefined;
      }
    );

    const { mutate, isLoading } = useMutation(quoteProject, {
      onSuccess: () => {
        enqueueSnackbar('Заявка успешно отправлена', {
          variant: 'success',
        });
        onClose();
      },
      onError: () => {
        enqueueSnackbar('Произошла ошибка', {
          variant: 'error',
        });
      },
    });

    const handleSubmit = useCallback(
      (data: FormValues) => {
        mutate({
          productId: quoteProjectId,
          fullName: data.fullName,
          city: data.city,
          email: data.email,
          phone: data.phone,
          notes: data.notes,
        });
      },
      [mutate, quoteProjectId]
    );

    if (!projectData?.data) {
      return <></>;
    }

    return (
      <Dialog
        title="Заявка на расчет материалов"
        open={!!quoteProjectId}
        onClose={onClose}
      >
        <RequestCostCalculationForm
          disabled={isLoading}
          onSubmit={handleSubmit}
          combinationName={projectData?.data?.combination.name || '...'}
        />
      </Dialog>
    );
  });
