import { ApiUrls } from '@modules/constants';

import { adminAxios } from '../admin-axios';


export interface DeleteCombinationImageRequestParams {
  combinationId: string;
  mediaId: string;
}

export const deleteCombinationImage = ({ mediaId, combinationId }: DeleteCombinationImageRequestParams) => {

  return adminAxios.delete(
    ApiUrls.ADMIN_COMBINATION_DELETE_IMAGE(combinationId, mediaId),
  );
};
