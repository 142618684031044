import { Box, styled } from '@mui/material';

export const CombinationImageItemContainer = styled(Box)`
    width: calc((100% - 40px) / 5);
    overflow: hidden;
    aspect-ratio: 2 / 1;
    box-sizing: border-box;
    margin-left: 10px;
    border-radius: 8px;
    position: relative;
`;

export const ActionIconContainer = styled(Box)`
    position: absolute;
    top: 5px;
    right: 5px;
    width: 35px;
    height: 35px;
    cursor: pointer;
;
`;
