import { ApiUrls } from '@modules/constants';

import { userAxios } from '../user-axios';

interface Project {
  id: string;
  srcMedia: {
    url: string;
  };
  distMedia: {
    url: string;
  };
}

interface GetProductsSuccessResponseData {
  total: number;
  data: Project[];
}

export const getProjects = () => {
  return userAxios.get<GetProductsSuccessResponseData>(ApiUrls.USER_PROJECTS);
};
