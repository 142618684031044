import { FC } from 'react';

import { Paper } from '@mui/material';
import { CombinationWidget } from '@widgets/combination-widget';

export const CombinationPage: FC = () => {
  return (
    <Paper sx={{ height: '100%' }}>
      <CombinationWidget />
    </Paper>
  );
};
