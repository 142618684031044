import { QueryClient, QueryClientProvider } from 'react-query';

const FIVE_MINUTES = 1000 * 60 * 5;
const FIVE_SECONDS = 1000 * 5;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 0,
      staleTime: FIVE_SECONDS,
      cacheTime: FIVE_SECONDS,
      refetchInterval: FIVE_MINUTES,
    },
  },
});

export { QueryClientProvider };
