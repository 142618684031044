import { FC, useState } from 'react';

import { Urls } from '@modules/constants';
import { Paper, Stack } from '@mui/material';
import { ContentHeader } from '@widgets/content-header';
import { ContentHeaderProvider } from '@widgets/content-header/content-header-context';
import { CreateSubcategoryDialog } from '@widgets/subcategory-form-dialog';
import { SubcategoryWidget } from '@widgets/subcategory-widget';

export const SubcategoryList: FC = () => {
  const [showForm, setShowForm] = useState(false);
  const toggleShowForm = () => setShowForm((value) => !value);

  return (
    <ContentHeaderProvider>
      <Paper sx={{ height: '100%' }}>
        <Stack sx={{ height: '100%' }} spacing={2}>
          <ContentHeader
            defaultTitle="Подкатегория"
            onCreateClick={toggleShowForm}
            breadcrumbs={[
              {
                label: 'Категории продукции',
                to: Urls.ADMIN,
              },
            ]}
          />
          <SubcategoryWidget />
        </Stack>

        <CreateSubcategoryDialog onClose={toggleShowForm} open={showForm} />
      </Paper>
    </ContentHeaderProvider>
  );
};
