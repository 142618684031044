import { useSnackbar } from 'notistack';
import { memo } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import { createSubcategory } from '@adapters/admin/subcategories';
import { queryClient } from '@modules/query-client';
import { Dialog } from '@ui-kit/dialog';

import { SubcategoryForm } from './components/subcategory-form';

interface SubCategoryFormDialogProps {
  onClose: () => void;
  onSubmit?: () => void;
  open: boolean;
}

export const CreateSubcategoryDialog = memo<SubCategoryFormDialogProps>(
  (props) => {
    const { onClose, open = true, onSubmit } = props;
    const { enqueueSnackbar } = useSnackbar();
    const { categoryId } = useParams();
    const { mutate } = useMutation(createSubcategory, {
      onSuccess: () => {
        enqueueSnackbar('Подкатегория успешно создана', {
          variant: 'success',
        });
        onSubmit && onSubmit();
        queryClient.invalidateQueries();
      },
      onError: () => {
        enqueueSnackbar('Произошла ошибка', {
          variant: 'error',
        });
      },
    });

    return (
      <Dialog title="Добавить подкатегорию" open={open} onClose={onClose}>
        <SubcategoryForm
          onSubmit={(data) => {
            onClose();
            categoryId && mutate({ categoryId, ...data });
          }}
        />
      </Dialog>
    );
  }
);
