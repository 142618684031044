import { styled } from '@mui/material';

export const Image = styled('img')`
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  box-sizing: border-box;
  object-fit: cover;
`;
export const ImgBlock = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 8px;
`;
