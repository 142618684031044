import { FC } from 'react';
import { useController } from 'react-hook-form';

import { Checkbox, FormControlLabel } from '@mui/material';

import { FormValues } from '../types';

export const VisibleField: FC = () => {
  const {
    field: { value: isVisible, onChange: onChangeVisible },
  } = useController<FormValues>({
    name: 'isVisible',
  });

  return (
    <FormControlLabel
      checked={isVisible === 'true'}
      onChange={(event, value) => {
        onChangeVisible(value ? 'true' : '');
      }}
      control={<Checkbox />}
      label="Показать название клиенту"
    />
  );
};
