import { Box, styled } from '@mui/material';

export const Container = styled(Box)`
  position: relative;
  width: 100%;
  height: 48px;
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Block = styled(Box)`
  position: absolute;
  left: 0px;
  top: 10px;
  width: 100%;
  height: 100%;
  color: #231f20;
  font-family: 'Open Sans';
  font-size: 22px;
  font-style: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
