import { ApiUrls } from '@modules/constants';

import { adminAxios } from '../admin-axios';
import { SubcategoryResponseDto } from './types';

interface GetSubcategorySuccessResponseData extends SubcategoryResponseDto {}

interface SubcategoryRequestParams {
  categoryId: string;
  subcategoryId: string;
}

export const getSubcategory = ({
  categoryId,
  subcategoryId,
}: SubcategoryRequestParams) => {
  return adminAxios.get<GetSubcategorySuccessResponseData>(
    ApiUrls.ADMIN_SUBCATEGORY_BY_ID(categoryId, subcategoryId)
  );
};
