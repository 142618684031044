import { AppStorage, AppStorageEvents } from '@modules/storage';

import { refresh } from './refresh-adapter';

let listeners: ((accessToken: string | undefined) => void)[] = [];

export const refreshAccessToken = () =>
  new Promise<string | undefined>((resolve) => {
    if (listeners.length) {
      listeners.push(resolve);
      return;
    }
    listeners.push(resolve);

    const refreshToken = AppStorage.get('ADMIN_AUTH').value?.refreshToken;

    refresh(refreshToken)
      .then((data) => {
        const value = data.data;
        AppStorage.set({
          key: 'ADMIN_AUTH',
          value,
        });
      })
      .catch(() => AppStorage.remove('ADMIN_AUTH'));
  });

AppStorageEvents.subscribe((payload) => {
  if (payload.key === 'ADMIN_AUTH') {
    const accessToken = payload.value?.accessToken;
    const emitList = listeners;
    listeners = [];
    emitList.forEach((e) => e(accessToken));
  }
});
