import { PropsWithChildren, memo } from 'react';
import { Link, useMatches } from 'react-router-dom';
import { styled } from 'styled-components';

import { Stack, styled as muiStyled } from '@mui/material';
import UiMenuItem from '@mui/material/MenuItem';

interface MenuItemProps extends PropsWithChildren {
  url: string;
}

const StyledLink = styled(Link)`
  color: inherit;
`;

const StyledMenuItem = muiStyled(UiMenuItem)`
  padding: 12px 20px;

  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  &.Mui-selected,
  &.Mui-selected:hover {
    background: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.common.white};
  }
`;

export const MenuItem = memo<MenuItemProps>((props) => {
  const { children, url } = props;
  const matches = useMatches();
  const isSelected = matches.some((m) => m.pathname === url);

  return (
    <StyledLink to={url}>
      <StyledMenuItem sx={{ mb: 2 }} selected={isSelected}>
        <Stack direction="row" alignItems="center" spacing={2}>
          {children}
        </Stack>
      </StyledMenuItem>
    </StyledLink>
  );
});
