import { useSnackbar } from 'notistack';
import { memo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { editSubcategory } from '@adapters/admin/subcategories';
import { getSubcategory } from '@adapters/admin/subcategories/get-subcategory';
import { queryClient } from '@modules/query-client';
import { Dialog } from '@ui-kit/dialog';

import { SubcategoryForm } from './components/subcategory-form';

interface SubcategoryFormDialogProps {
  onClose: () => void;
  onSubmit?: () => void;
  open: boolean;
  subcategoryId: string;
}

export const EditSubcategoryDialog = memo<SubcategoryFormDialogProps>(
  (props) => {
    const { onClose, open = true, onSubmit, subcategoryId } = props;
    const { enqueueSnackbar } = useSnackbar();
    const { categoryId } = useParams();
    const { mutate } = useMutation(editSubcategory, {
      onSuccess: () => {
        enqueueSnackbar('Подкатегория успешно изменена', {
          variant: 'success',
        });

        queryClient.invalidateQueries().then(() => {
          queryClient.invalidateQueries();
        });

        onSubmit && onSubmit();
      },
      onError: () => {
        enqueueSnackbar('Произошла ошибка', {
          variant: 'error',
        });
      },
    });

    const { data } = useQuery(
      ['subcategory', categoryId, subcategoryId],
      () => {
        if (subcategoryId) {
          return getSubcategory({
            categoryId: categoryId || '',
            subcategoryId,
          });
        }
      },
      {
        cacheTime: 0,
        onError: () => {
          enqueueSnackbar('Произошла ошибка', {
            variant: 'error',
          });
          onClose();
        },
      }
    );

    const subcategoriesData = data?.data;

    if (subcategoriesData) {
      return (
        <Dialog
          title="Редактировать подкатегорию"
          open={open}
          onClose={onClose}
        >
          <SubcategoryForm
            defaultValues={{ name: subcategoriesData.name }}
            onSubmit={(data) => {
              onClose();
              categoryId && mutate({ categoryId, subcategoryId, ...data });
            }}
            isEdit
          />
        </Dialog>
      );
    }
    return <></>;
  }
);
