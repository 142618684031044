import { useSnackbar } from 'notistack';
import React, { FC, useCallback, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { saveCombination } from '@adapters/admin/combinations/save-combination';
import { savePart } from '@adapters/admin/combinations/save-part';
import { PART_LIST, Urls } from '@modules/constants';
import { queryClient } from '@modules/query-client';
import { Alert, Box, Breadcrumbs, CircularProgress, Stack } from '@mui/material';

import { CombinationForm } from './components/combination-form';
import { useCategories, useCombination } from './hooks';
import { FormValues } from './types';
import { getIsVisible, getProductId } from './utils';
import {
  uploadCombinationImage,
} from '@adapters/admin/combinations/upload-combination-image';
import { SelectedFile } from '@ui-kit/file-picker';
import { CombinationMedia } from '@widgets/combination-widget/components/combination-media/combination-media';
import { DeleteCombinationImageDialog } from '@widgets/combination-widget/components/delete-combination-image-dialog';

export const CombinationWidget: FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const requestData = useRef<FormValues>({} as FormValues);
  const [editId, setEditId] = useState<string>();
  const [deleteMediaId, setDeleteMediaId] = useState<string | undefined>();

  const combinationId =
    (params.combinationId === 'create' ? undefined : params.combinationId) ||
    editId;

  const { combination, isLoading, isError } = useCombination(combinationId);

  const { mutate: mutateSavePart, isLoading: isSavePartLoading } = useMutation(
    (combinationId: string) => {
      const data = requestData.current;

      return Promise.all(
        PART_LIST.map((type) => {
          return savePart({
            id: combination.combinationParts?.get(type)?.id,
            part: type,
            productId: getProductId(data, type),
            combinationId,
            isVisible: getIsVisible(data, type) === 'true',
          });
        })
      );
    },
    {
      onSuccess: () => {
        const notificationMessage = combinationId
          ? 'Комбинация успешно отредактирована'
          : 'Комбинация успешно создана';
        enqueueSnackbar(notificationMessage, {
          variant: 'success',
        });
        queryClient.invalidateQueries();

        navigate(Urls.ADMIN_COMBINATIONS);
      },
      onError: () => {
        enqueueSnackbar('Произошла ошибка', {
          variant: 'error',
        });
        queryClient.invalidateQueries();
      },
    }
  );

  const { mutate: mutateSaveCombination, isLoading: isSaveCombinationLoading } =
    useMutation(
      () => {
        const data = requestData.current;
        return saveCombination({
          id: combination?.id,
          name: data.name,
          description: data.description,
          isVisible: data.isVisible === 'true',
        });
      },
      {
        onSuccess: (data) => {
          if (!data) {
            enqueueSnackbar('Произошла ошибка', {
              variant: 'error',
            });
            queryClient.invalidateQueries();
            return;
          }
          setEditId(data.data.id);
          mutateSavePart(data.data.id);
        },
        onError: () => {
          enqueueSnackbar('Произошла ошибка', {
            variant: 'error',
          });
          queryClient.invalidateQueries();
        },
      }
    );

  const {mutate: mutateSaveCombinationImage} = useMutation(uploadCombinationImage, {
    onSuccess: () => {
      enqueueSnackbar('Изображение успешно загружено', {
        variant: 'success',
      });
      queryClient.invalidateQueries();
    },
    onError: () => {
      queryClient.invalidateQueries();
      enqueueSnackbar('Не удалось загрузить картинку', {
        variant: 'error',
      });
    },
  })

  const save = useCallback(
    (data: FormValues) => {
      requestData.current = data;
      mutateSaveCombination();
    },
    [mutateSaveCombination]
  );

  const saveFile = useCallback(
    (file: SelectedFile | undefined) => {
      if (file && file.file && combinationId) {
        mutateSaveCombinationImage({
          file: file.file,
          combinationId
        });
      }

    },
    [mutateSaveCombinationImage, combinationId]
  );

  const onDeleteFileClick = useCallback((mediaId: string) => {
    setDeleteMediaId(mediaId);
  }, [])

  const {
    categoriesMap,
    isError: isCategoriesError,
    isLoading: isCategoriesLoading,
  } = useCategories();

  if (isError || isCategoriesError) {
    return (
      <Alert severity="error">Произошла ошибка при получении данных</Alert>
    );
  }

  if (isLoading || isCategoriesLoading) {
    return (
      <Stack
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100%"
      >
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <>
      <DeleteCombinationImageDialog
        onClose={() => {
          setDeleteMediaId(undefined)
        }}
        combinationId={combinationId}
        mediaId={deleteMediaId}
      />
      <Box>
        <Breadcrumbs>
          <Link color="inherit" to={Urls.ADMIN_COMBINATIONS}>
            Комбинация
          </Link>
        </Breadcrumbs>
      </Box>
      {combinationId && (
        <CombinationMedia
          media={combination?.media}
          onFileChange={saveFile}
          onFileDelete={onDeleteFileClick}
        />
      )}
      <CombinationForm
        categoriesMap={categoriesMap}
        isDisabled={isSaveCombinationLoading || isSavePartLoading || isLoading}
        onSubmit={save}
        defaultForm={combination?.defaultForm}
      />
    </>
  );
};
