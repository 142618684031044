export default (
  <>
    <path
      d="M9 18L15 12L9 6"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
