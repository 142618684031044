import { ApiUrls } from '@modules/constants';

import { adminAxios } from '../admin-axios';

interface DeleteCombinationRequestParams {
  id: string;
}

export const deleteCombination = ({ id }: DeleteCombinationRequestParams) => {
  return adminAxios.delete<boolean>(ApiUrls.ADMIN_COMBINATION_BY_ID(id));
};
