import { ApiUrls } from '@modules/constants';

import { userAxios } from '../user-axios';

type CreateDecorationSuccessResponseData = boolean;

interface QuoteProjectRequestParams {
  productId: string;
  fullName: string;
  city: string;
  email: string;
  phone: string;
  notes: string;
}

export const quoteProject = ({
  productId,
  ...data
}: QuoteProjectRequestParams) => {
  return userAxios.post<CreateDecorationSuccessResponseData>(
    ApiUrls.USER_QUOTE_PROJECT(productId),
    data
  );
};
