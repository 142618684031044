import { useMemo } from 'react';
import { useQuery } from 'react-query';

import {
  CombinationPartResponseDto,
  getCombination,
} from '@adapters/admin/combinations';
import { CombinationPartType, PART_LIST } from '@modules/constants';

import { getDefaultForm, setIsVisible, setProductId } from '../utils';

export const useCombination = (combinationId?: string) => {
  const {
    data: combinationData,
    isError,
    isLoading,
  } = useQuery(['combination', combinationId], () =>
    combinationId
      ? getCombination({
          combinationId,
        })
      : undefined
  );

  const combination = useMemo(() => {
    const defaultForm = getDefaultForm();
    if (!combinationData) {
      return {
        defaultForm,
      };
    }
    const data = combinationData.data;
    const description = data.description;
    const id = data.id;
    const name = data.name;
    const isVisible = data.isVisible;
    const media = data.media.length > 0 ? data.media : undefined;
    const combinationParts = new Map<
      CombinationPartType,
      CombinationPartResponseDto
    >();
    data.combinationParts.forEach((part) => {
      const partType = part.part.toUpperCase() as CombinationPartType;
      combinationParts.set(partType, {
        ...part,
        part: partType,
      });
    }, []);

    defaultForm.name = name;
    defaultForm.description = description;
    defaultForm.isVisible = isVisible ? 'true' : '';

    PART_LIST.forEach((type) => {
      const part = combinationParts.get(type);
      if (!part) {
        return;
      }
      setIsVisible(defaultForm, type, part.isVisible ? 'true' : '');
      setProductId(defaultForm, type, part.product?.id || '');
    });

    return {
      id,
      name,
      isVisible,
      combinationParts,
      defaultForm,
      media
    };
  }, [combinationData]);

  return useMemo(
    () => ({
      combination,
      isError,
      isLoading,
    }),
    [combination, isError, isLoading]
  );
};
