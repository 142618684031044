import { AppStorageEvents } from './app-storage-events';
import {
  AppStorageKeys,
  AppStoragePayload,
  StorageValue,
} from './app-storage-types';

const set = (data: AppStoragePayload): void => {
  try {
    const json = JSON.stringify(data);
    localStorage.setItem(data.key, json);
    AppStorageEvents.emit(data);
  } catch (error) {
    console.error(error);
  }
};

const get = <Key extends AppStorageKeys>(key: Key) => {
  try {
    const json = localStorage.getItem(key);
    if (json) {
      return JSON.parse(json) as StorageValue<Key>;
    }
  } catch (error) {
    console.error(error);
  }
  return {
    key,
    value: undefined,
  } as StorageValue<Key>;
};

const remove = <Key extends AppStorageKeys>(key: Key): void => {
  try {
    localStorage.removeItem(key);
    AppStorageEvents.emit({ key });
  } catch (error) {
    console.error(error);
  }
};

// eslint-disable-next-line
export const AppStorage = { get, set, remove };
