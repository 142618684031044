import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Urls } from '@modules/constants';
import { Paper, Stack } from '@mui/material';
import { CombinationsWidget } from '@widgets/combinations-widget';
import { ContentHeader } from '@widgets/content-header';
import { ContentHeaderProvider } from '@widgets/content-header/content-header-context';

export const CombinationsList: FC = () => {
  const navigate = useNavigate();

  return (
    <ContentHeaderProvider>
      <Paper sx={{ height: '100%' }}>
        <Stack sx={{ height: '100%' }} spacing={2}>
          <ContentHeader
            defaultTitle="Комбинации"
            onCreateClick={() => navigate(Urls.ADMIN_COMBINATION.url('create'))}
          />
          <CombinationsWidget />
        </Stack>
      </Paper>
    </ContentHeaderProvider>
  );
};
