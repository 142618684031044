import { memo, useMemo, useState } from 'react';
import { useController } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useDebounce } from 'use-debounce';

import { getProduct, getProducts } from '@adapters/admin/products';
import { COMBINATION_PARTS, CombinationPartType } from '@modules/constants';
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
} from '@mui/material';

import { FormValues } from '../types';
import { getIsVisibleName, getProductIdName } from '../utils';

const MAX_ROWS = 40;

interface ProductAutocompleteProps {
  type: CombinationPartType;
  categoriesMap: Map<string | undefined | null, string[]>;
}

export const ProductAutocomplete = memo<ProductAutocompleteProps>((props) => {
  const { type, categoriesMap } = props;
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 1300);
  const page = 1;

  const filterCategories = categoriesMap.get(type);

  const { data: productsData } = useQuery(
    ['products', page, debouncedSearch, filterCategories],
    () =>
      getProducts({
        page,
        pageSize: MAX_ROWS,
        search: debouncedSearch,
        filterCategories,
      })
  );

  const {
    field: { value: productId, onChange },
  } = useController<FormValues>({
    name: getProductIdName(type),
    rules: {
      required: true,
    },
  });

  const {
    field: { value: isVisible, onChange: onChangeVisible },
  } = useController<FormValues>({
    name: getIsVisibleName(type),
  });

  const { data: productData } = useQuery(['category', productId], () =>
    productId ? getProduct({ productId }) : undefined
  );

  const { optionsNames, options } = useMemo(() => {
    const optionsNames = new Map<string, string>();
    if (productData) {
      const { id, name } = productData.data;
      optionsNames.set(id, name);
    }
    if (productsData) {
      productsData.data.data.forEach(({ id, name }) => {
        optionsNames.set(id, name);
      });
    }
    return {
      optionsNames,
      options: Array.from(optionsNames.keys()),
    };
  }, [productData, productsData]);

  return (
    <Grid item xs minWidth={300}>
      <Stack direction="column" spacing={1}>
        <Autocomplete
          options={options}
          value={productId || ''}
          onChange={(event, value) => {
            onChange(value);
          }}
          getOptionLabel={(option) => optionsNames.get(option) || ''}
          getOptionKey={(option) => option || 'key'}
          fullWidth
          inputValue={search}
          onInputChange={(e, value) => {
            setSearch(value);
          }}
          noOptionsText="Продукт не найден"
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Выбрать продукт"
              label={COMBINATION_PARTS[type].label}
            />
          )}
        />

        <FormControlLabel
          checked={isVisible === 'true'}
          onChange={(event, value) => {
            onChangeVisible(value ? 'true' : '');
          }}
          control={<Checkbox />}
          label="Показать клиенту"
        />
      </Stack>
    </Grid>
  );
});
