import { memo } from 'react';

import { Button, Stack, Typography, Dialog as UiDialog } from '@mui/material';
import Icons from '@ui-kit/icons';

interface DialogProps {
  isPending?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  open: boolean;
  title: string;
  subtitle: string;
  cancelText?: string;
  confirmText?: string;
}

export const DeleteDialog = memo<DialogProps>((props) => {
  const {
    isPending,
    onClose,
    onConfirm,
    open,
    title,
    subtitle,
    cancelText,
    confirmText,
  } = props;

  return (
    <UiDialog sx={{ mx: 0 }} fullWidth open={open} onClose={onClose}>
      <Stack spacing={4}>
        <Stack direction="row" spacing={4} alignItems="center">
          <Stack
            sx={{
              flexShrink: 0,
              width: 48,
              height: 48,
              background: (theme) => theme.palette.grey[200],
              borderRadius: '50%',
              color: (theme) => theme.palette.warning.main,
            }}
            justifyContent="center"
            alignItems="center"
          >
            <Icons.Trash size={24} />
          </Stack>
          <Stack>
            <Typography
              variant="subtitle1"
              color={(theme) => theme.palette.warning.main}
            >
              {title}
            </Typography>
            <Typography
              variant="body1"
              color={(theme) => theme.palette.grey[600]}
            >
              {subtitle}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={4}>
          <Button fullWidth onClick={onClose} variant="outlined" size="small">
            {cancelText || 'Отмена'}
          </Button>
          <Button
            disabled={isPending}
            fullWidth
            onClick={onConfirm}
            variant="contained"
            size="small"
          >
            {confirmText || 'Удалить'}
          </Button>
        </Stack>
      </Stack>
    </UiDialog>
  );
});
