import { memo } from 'react';

import {
  SpecificationItem,
  SpecificationItemProps,
} from '../specification-item';
import { Container } from './specification.styles';

interface SpecificationProps {
  items: SpecificationItemProps[];
}

export const Specification = memo<SpecificationProps>(({ items }) => {
  return (
    <Container>
      {items.map((props) => (
        <SpecificationItem key={props.id} {...props} />
      ))}
    </Container>
  );
});
