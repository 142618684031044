import {
  PropsWithChildren,
  createContext,
  memo,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useDebounce } from 'use-debounce';

interface ContentHeaderContextType {
  title: string;
  changeTitle: (value: string) => void;
  search?: string;
  debouncedSearch?: string;
  changeSearch: (value: string) => void;
}

const defaultContext: ContentHeaderContextType = {
  title: '',
  changeTitle: () => {},
  changeSearch: () => {},
};

const Context = createContext(defaultContext);

export const ContentHeaderProvider = memo<PropsWithChildren>(({ children }) => {
  const [title, changeTitle] = useState(defaultContext.title);
  const [search, changeSearch] = useState(defaultContext.search);
  const [debouncedSearch] = useDebounce(search, 1300);

  const context = useMemo(
    () => ({
      title,
      changeTitle,
      search,
      debouncedSearch,
      changeSearch,
    }),

    [title, debouncedSearch, search]
  );

  return <Context.Provider value={context}>{children}</Context.Provider>;
});

export const useContentHeader = () => {
  return useContext(Context);
};
