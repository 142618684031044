import { PropsWithChildren, memo } from 'react';

import { Box, Stack, TableCell } from '@mui/material';

export const CellText = memo<PropsWithChildren>(({ children }) => {
  return (
    <TableCell
      sx={{
        width: '100%',
        minWidth: 128,
        position: 'relative',
      }}
    >
      <Stack
        padding={2}
        justifyContent="center"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
        }}
      >
        <Box
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {children}
        </Box>
      </Stack>
    </TableCell>
  );
});
