import { FC, useState } from 'react';

import { Paper, Stack } from '@mui/material';
import { CreateCategoryFormDialog } from '@widgets/category-form-dialog';
import { CategoryWidget } from '@widgets/category-widget';
import { ContentHeader } from '@widgets/content-header';
import { ContentHeaderProvider } from '@widgets/content-header/content-header-context';

export const CategoryList: FC = () => {
  const [showForm, setShowForm] = useState(false);
  const toggleShowForm = () => setShowForm((value) => !value);

  return (
    <ContentHeaderProvider>
      <Paper sx={{ height: '100%' }}>
        <Stack sx={{ height: '100%' }} spacing={2}>
          <ContentHeader
            defaultTitle="Категории продукции"
            onCreateClick={toggleShowForm}
          />
          <CategoryWidget />
        </Stack>

        <CreateCategoryFormDialog onClose={toggleShowForm} open={showForm} />
      </Paper>
    </ContentHeaderProvider>
  );
};
