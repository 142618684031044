import { ApiUrls } from '@modules/constants';

import { adminAxios } from '../admin-axios';

interface LoginParams {
  email: string;
  password: string;
}

interface LoginSuccessResponseData {
  accessToken: string;
  refreshToken: string;
}

export const login = (data: LoginParams) => {
  return adminAxios.post<LoginSuccessResponseData>(ApiUrls.ADMIN_LOGIN, data);
};
