export const BASE_API =
  process.env.REACT_APP_API_SERVER_ADDRESS ||
  'https://stage-api.renovation.grandline.com';

export const ApiUrls = {
  //user
  USER_GET_USER: '/user/auth/test-user',
  USER_LOGIN: '/user/auth/login',
  USER_SUBSCRIBE_PROJECT: (projectId: string) =>
    `/user/projects/${projectId}/sse`,
  USER_PROJECT_BY_ID: (projectId: string) => `/user/projects/${projectId}`,
  USER_PROJECTS: '/user/projects',
  USER_QUOTE_PROJECT: (projectId: string) =>
    `/user/projects/${projectId}/quote`,
  USER_DECORATION_BY_ID: (projectId: string, decoratorId: string) =>
    `/user/projects/${projectId}/${decoratorId}`,
  USER_REGENERATE_PROJECT: '/user/projects/try-more',

  //admin
  ADMIN_AUTH: '/admin/auth',
  ADMIN_GET_ME: '/admin/auth/me',
  ADMIN_LOGIN: '/admin/auth/login',
  ADMIN_REFRESH: '/admin/auth/refresh',

  ADMIN_CATEGORIES: '/admin/categories',
  ADMIN_CATEGORY_BY_ID: (categoryId: string) =>
    `/admin/categories/${categoryId}`,

  ADMIN_SUBCATEGORIES: (categoryId: string) =>
    `/admin/categories/${categoryId}/subcategories`,
  ADMIN_SUBCATEGORY_BY_ID: (categoryId: string, subcategoryId: string) =>
    `/admin/categories/${categoryId}/subcategories/${subcategoryId}`,

  ADMIN_COMBINATIONS: '/admin/combinations',
  ADMIN_COMBINATION_BY_ID: (combinationId: string) =>
    `/admin/combinations/${combinationId}`,
  ADMIN_COMBINATION_UPLOAD_IMAGE: (combinationId: string) =>
    `/admin/combinations/${combinationId}/upload-image`,
  ADMIN_COMBINATION_DELETE_IMAGE: (combinationId: string, mediaId: string) =>
    `/admin/combinations/${combinationId}/${mediaId}`,

  ADMIN_PART_BY_ID: (combinationId: string, partId: string) =>
    `/admin/combinations/${combinationId}/parts/${partId}`,
  ADMIN_PARTS: (combinationId: string) =>
    `/admin/combinations/${combinationId}/parts`,

  ADMIN_PRODUCTS: '/admin/product',
  ADMIN_PRODUCT_BY_ID: (productId: string) => `/admin/product/${productId}`,
  ADMIN_PRODUCT_UPLOAD: (productId: string) =>
    `/admin/product/${productId}/upload-image`,
};
