import { styled } from '@mui/material';

export const ImageContainer = styled('img')`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  object-fit: cover;
  border-radius: 8px;
  overflow: hidden;
`;

export const Container = styled('div')`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
