import { MuiTableContainerType } from './table.types';

export const MuiTableContainer: MuiTableContainerType = {
  styleOverrides: {
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      gap: 16,
    },
  },
};
