import { memo } from 'react';

import { AddCircle } from '@mui/icons-material';
import { IconButton, Stack, Typography } from '@mui/material';

import { useContentHeader } from './content-header-context';

interface TitleHeaderProps {
  defaultTitle: string;
  onCreateClick: () => void;
}

export const TitleHeader = memo<TitleHeaderProps>((props) => {
  const { defaultTitle, onCreateClick } = props;
  const { title } = useContentHeader();

  return (
    <Stack direction="row" alignItems="center" mt={2}>
      <Typography
        variant="subtitle1"
        sx={{
          maxWidth: 320,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {title || defaultTitle}
      </Typography>
      <IconButton
        onClick={onCreateClick}
        sx={{ mx: 1 }}
        color="primary"
        aria-label="toggle password visibility"
        edge="end"
      >
        <AddCircle sx={{ width: '32px', height: '32px' }} />
      </IconButton>
    </Stack>
  );
});
