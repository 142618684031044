import {
  COMBINATION_PARTS,
  CombinationPartType,
  PART_LIST,
} from '@modules/constants';

import { FormValues } from './types';

export const getIsVisibleName = (type: CombinationPartType) =>
  `${type}_IS_VISIBLE`;
export const getProductIdName = (type: CombinationPartType) =>
  `${type}_PRODUCT_ID`;

export const getIsVisible = (formData: FormValues, type: CombinationPartType) =>
  formData[getIsVisibleName(type)] || '';
export const setIsVisible = (
  formData: FormValues,
  type: CombinationPartType,
  value: string
) => {
  formData[getIsVisibleName(type)] = value;
};

export const getProductId = (formData: FormValues, type: CombinationPartType) =>
  formData[getProductIdName(type)] || '';
export const setProductId = (
  formData: FormValues,
  type: CombinationPartType,
  value: string
) => {
  formData[getProductIdName(type)] = value;
};

export const getDefaultForm = () => {
  const defaultForm: FormValues = {
    isVisible: 'true',
  };

  PART_LIST.forEach((type) => {
    setIsVisible(
      defaultForm,
      type,
      COMBINATION_PARTS[type].defaultVisible ? 'true' : ''
    );
  });
  return defaultForm;
};
