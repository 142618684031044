import { useSnackbar } from 'notistack';
import { memo } from 'react';
import { useMutation } from 'react-query';

import { queryClient } from '@modules/query-client';
import { DeleteDialog } from '@ui-kit/delete-dialog';
import { deleteCombinationImage } from '@adapters/admin/combinations/delete-combination-image';

interface DeleteCombinationImageDialogProps {
  onClose: () => void;
  combinationId?: string;
  mediaId?: string;
}

export const DeleteCombinationImageDialog = memo<DeleteCombinationImageDialogProps>(
  ({ onClose, mediaId, combinationId }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { mutate: mutateDeleteCombinationImage, isLoading } = useMutation(
      deleteCombinationImage,
      {
        onSuccess: () => {
          enqueueSnackbar('Изображение успешно удалено', {
            variant: 'success',
          });
          onClose();
          queryClient.invalidateQueries();
        },
        onError: () => {
          enqueueSnackbar('Произошла ошибка', {
            variant: 'error',
          });
          onClose();
          queryClient.invalidateQueries();
        },
      }
    );
    if (!mediaId || !combinationId) {
      return <></>;
    }
    return (
      <DeleteDialog
        isPending={isLoading}
        title="Удалить изображение комбинации?"
        subtitle="Изображение нельзя будет восстановить."
        open={!!mediaId}
        onClose={onClose}
        onConfirm={() => {
          mutateDeleteCombinationImage({ combinationId, mediaId });
        }}
      />
    );
  }
);
