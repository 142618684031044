import { memo, useCallback, useEffect, useState } from 'react';

import { Paper, Stack } from '@mui/material';

import { BackDialog } from './components/back-dialog';
import { CombinationName } from './components/combination-name';
import { Controls } from './components/controls';
import { LimitDialog } from './components/limit-dialog';
import { Result } from './components/result';
import { Specification } from './components/specification';
import { Tabs } from './components/tabs/tabs';
import {
  useDeleteProject,
  useProducts,
  useProjectById,
  useRegenerateProject,
} from './hooks';
import { useSubscribeProject } from './hooks/use-subscribe-product';
import { useUserProductState } from './state';

interface UserProductWidgetProps {
  onRequestCostCalculation: (productId: string) => void;
  onEmpty: () => void;
}

export const UserProductWidget = memo<UserProductWidgetProps>((props) => {
  const { onRequestCostCalculation, onEmpty } = props;

  const [shortControls, setShortControls] = useState(true);

  const { tabItems, productId, onChangeProduct } = useProducts(onEmpty);
  useSubscribeProject();

  const { resultUrl, combinationName, specifications, isPending } =
    useProjectById(productId);

  const {
    state: {
      wait: { projectId: processProjectId },
    },
  } = useUserProductState();

  const { onRegenerate, onLimitClose, isLimit } = useRegenerateProject(
    tabItems.length,
    onChangeProduct
  );
  const { isDeleteDialog, onDeleteDialogClose, onDelete, openDialog } =
    useDeleteProject();

  useEffect(() => {
    setShortControls(true);
  }, [productId]);

  const handleRequestCostCalculation = useCallback(() => {
    onRequestCostCalculation(productId);
  }, [productId, onRequestCostCalculation]);

  return (
    <Paper
      sx={{
        width: '100%',
        maxWidth: 1120,
        minHeight: 552,
      }}
    >
      <LimitDialog
        isVisible={isLimit}
        onClose={onLimitClose}
        onDelete={onDelete}
      />
      <BackDialog
        isVisible={isDeleteDialog}
        onDelete={onDelete}
        onClose={onDeleteDialogClose}
      />
      <Stack gap={2}>
        <Tabs items={tabItems} value={productId} onChange={onChangeProduct} />
        <Result src={resultUrl} />
        {!shortControls && (
          <>
            <CombinationName text={combinationName || ''} />
            <Specification items={specifications} />
          </>
        )}
        <Controls
          isPending={isPending || !!processProjectId}
          isShort={shortControls}
          onSpecificationToggle={() => setShortControls(!shortControls)}
          onRequestCostCalculation={handleRequestCostCalculation}
          onRepeat={onRegenerate}
          onBack={openDialog}
          combinationName={combinationName}
        />
      </Stack>
    </Paper>
  );
});
