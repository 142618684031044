export const Urls = {
  HOME: '/',

  ADMIN: '/admin',
  ADMIN_LOGIN: '/login',
  ADMIN_CATEGORIES: '/admin/categories',
  ADMIN_CATEGORY: {
    path: '/admin/categories/:categoryId',
    url: (categoryId: string) => `/admin/categories/${categoryId}`,
  },
  ADMIN_PRODUCTS: '/admin/products',
  ADMIN_COMBINATIONS: '/admin/combinations',

  ADMIN_COMBINATION: {
    path: '/admin/combinations/:combinationId',
    url: (combinationId: string) => `/admin/combinations/${combinationId}`,
  },
};
