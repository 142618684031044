export default (
  <>
    <path
      d="M8 2.5H4C2.89543 2.5 2 3.39543 2 4.5V8.5C2 9.60457 2.89543 10.5 4 10.5H8C9.10457 10.5 10 9.60457 10 8.5V4.5C10 3.39543 9.10457 2.5 8 2.5Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 2.5C15.1 2.5 16 3.4 16 4.5V8.5C16 9.6 15.1 10.5 14 10.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 2.5C21.1 2.5 22 3.4 22 4.5V8.5C22 9.6 21.1 10.5 20 10.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 18.5H5C3.3 18.5 2 17.2 2 15.5V14.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 21.5L10 18.5L7 15.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 14.5H16C14.8954 14.5 14 15.3954 14 16.5V20.5C14 21.6046 14.8954 22.5 16 22.5H20C21.1046 22.5 22 21.6046 22 20.5V16.5C22 15.3954 21.1046 14.5 20 14.5Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
