import { PropsWithChildren, memo } from 'react';

import { Close } from '@mui/icons-material';
import { Box, IconButton, Typography, Dialog as UiDialog } from '@mui/material';

interface DialogProps extends PropsWithChildren {
  onClose: () => void;
  open: boolean;
  title: string;
}

export const Dialog = memo<DialogProps>((props) => {
  const { onClose, open, title, children } = props;

  return (
    <UiDialog sx={{ mx: 0 }} fullWidth open={open} onClose={onClose}>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <Typography variant="subtitle1" mb={4}>
        {title}
      </Typography>
      <Box>{children}</Box>
    </UiDialog>
  );
});
