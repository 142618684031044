import axios from 'axios';

import { BASE_API } from '@modules/constants';
import { AppStorage } from '@modules/storage';

import { refreshAccessToken } from './refresh-access-token';

export const adminAxios = axios.create();

adminAxios.interceptors.request.use(
  (config) => {
    const accessToken = AppStorage.get('ADMIN_AUTH').value?.accessToken;
    config.baseURL = BASE_API;
    if (accessToken) {
      config.headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

adminAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if ([401, 403].includes(error.response.status) && !originalRequest._retry) {
      originalRequest._retry = true;
      const accessToken = await refreshAccessToken();
      if (accessToken) {
        axios.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${accessToken}`;
        return adminAxios(originalRequest);
      }
    }
    return Promise.reject(error);
  }
);
