import { FC, useMemo, useState } from 'react';
import { useController } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useDebounce } from 'use-debounce';

import { getCategories, getCategory } from '@adapters/admin/categories';
import { Autocomplete, TextField } from '@mui/material';

import { FormValues } from '../types';

const MAX_ROWS = 40;

export const CategoryAutocomplete: FC = () => {
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 1300);
  const page = 1;

  const { data: categoriesData } = useQuery(
    ['categories', page, debouncedSearch],
    () =>
      getCategories({
        page,
        pageSize: MAX_ROWS,
        search: debouncedSearch,
      })
  );

  const {
    field: { value: categoryId, onChange },
  } = useController<FormValues>({
    name: 'categoryId',
    rules: {
      required: true,
    },
  });

  const { data: categoryData } = useQuery(['category', categoryId], () =>
    categoryId ? getCategory({ categoryId }) : undefined
  );

  const { optionsNames, options } = useMemo(() => {
    const optionsNames = new Map<string, string>();
    if (categoryData) {
      const { id, name } = categoryData.data;
      optionsNames.set(id, name);
    }
    if (categoriesData) {
      categoriesData.data.data.forEach(({ id, name }) => {
        optionsNames.set(id, name);
      });
    }
    return {
      optionsNames,
      options: Array.from(optionsNames.keys()),
    };
  }, [categoryData, categoriesData]);

  return (
    <Autocomplete
      options={options}
      value={categoryId || ''}
      onChange={(event, value) => {
        onChange(value);
      }}
      getOptionLabel={(option) => optionsNames.get(option) || ''}
      getOptionKey={(option) => option || 'key'}
      fullWidth
      inputValue={search}
      onInputChange={(e, value) => {
        setSearch(value);
      }}
      noOptionsText="Категория не найдена"
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Выбрать категорию"
          label="Выбрать категорию"
        />
      )}
    />
  );
};
