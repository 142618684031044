import { useCallback, useMemo, useState } from 'react';
import { useMutation } from 'react-query';

import { regenerateProject } from '@adapters/user/project';
import { queryClient } from '@modules/query-client';

export const useRegenerateProject = (
  projectCount: number,
  onChangeProduct: (value: string) => void
) => {
  const [isLimit, setIsLimit] = useState(false);

  const { mutate } = useMutation(regenerateProject, {
    onSuccess: (data) => {
      queryClient.invalidateQueries();
      onChangeProduct(data.data.id);
    },
  });

  const onRegenerate = useCallback(() => {
    if (projectCount >= 5) {
      setIsLimit(true);
      return;
    }
    mutate();
  }, [projectCount, mutate]);

  const onLimitClose = useCallback(() => {
    setIsLimit(false);
  }, []);

  return useMemo(() => {
    return {
      onRegenerate,
      onLimitClose,
      isLimit,
    };
  }, [isLimit, onLimitClose, onRegenerate]);
};
