import { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { getProducts } from '@adapters/admin/products';
import {
  Alert,
  CircularProgress,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { CellControls, CellImg, CellText } from '@ui-kit/table';
import { useHeaderContext } from '@widgets/content-header';
import { EditProductDialog } from '@widgets/product-form-dialog';

import { DeleteProductDialog } from './components/delete-product-dialog';

const ROWS_PER_PAGE = 40;

export const ProductsWidget: FC = () => {
  const [page, setPage] = useState(1);
  const [deleteId, setDeleteId] = useState('');
  const [editId, setEditId] = useState('');

  const { debouncedSearch } = useHeaderContext();

  const { data, isError, isLoading } = useQuery(
    ['products', page, debouncedSearch],
    () =>
      getProducts({ page, pageSize: ROWS_PER_PAGE, search: debouncedSearch })
  );

  const productData = data?.data?.data || [];
  const pageTotal = Math.floor(
    (data?.data && data?.data?.total / ROWS_PER_PAGE + 1) || 1
  );

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (page < 1) {
      setPage(1);
    }
    if (page > pageTotal) {
      setPage(pageTotal);
    }
  }, [isLoading, page, pageTotal]);

  if (isError) {
    return (
      <Alert severity="error">Произошла ошибка при получении данных</Alert>
    );
  }

  if (isLoading) {
    return (
      <Stack
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100%"
      >
        <CircularProgress />;
      </Stack>
    );
  }

  return (
    <>
      <DeleteProductDialog
        productId={deleteId}
        onClose={() => {
          setDeleteId('');
        }}
      />
      <EditProductDialog
        productId={editId}
        onClose={() => setEditId('')}
        open={!!editId}
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 48 }}>№</TableCell>
              <TableCell>Название</TableCell>
              <TableCell>Категория</TableCell>
              <TableCell>Подкатегория</TableCell>
              <TableCell>Артикул</TableCell>
              <TableCell>Изображение</TableCell>
              <TableCell style={{ width: 104 }}>Опции</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productData.map((row, index) => (
              <TableRow key={row.id}>
                <TableCell>{index + 1}</TableCell>
                <CellText>{row.name}</CellText>
                <CellText>{row.category?.name}</CellText>
                <CellText>{row.subCategory?.name}</CellText>
                <TableCell>{row.sku}</TableCell>
                <CellImg
                  visualType={row.visualType}
                  src={row?.media?.url}
                  color={row.color}
                />
                <CellControls
                  onDelete={() => {
                    setDeleteId(row.id);
                  }}
                  onEdit={() => {
                    setEditId(row.id);
                  }}
                />
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          variant="outlined"
          count={pageTotal}
          page={page}
          onChange={(event, value) => {
            setPage(value);
          }}
          disabled={pageTotal < 2}
        />
      </TableContainer>
    </>
  );
};
