import { memo, useCallback, useState } from 'react';

import { CircularProgress } from '@mui/material';

import { Container, ImageContainer } from './image.styles';

interface ImageProps {
  url?: string;
}

export const Image = memo<ImageProps>(({ url }) => {
  const [loaded, setLoaded] = useState(false);

  const onLoaded = useCallback(() => {
    setLoaded(true);
  }, []);

  return (
    <Container>
      {!loaded && <CircularProgress />}
      <ImageContainer
        style={{ opacity: loaded ? 1 : 0 }}
        onLoad={onLoaded}
        src={url}
      />
    </Container>
  );
});
