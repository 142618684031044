import { memo, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import { Stack, Typography } from '@mui/material';

import {
  Format,
  ImageIcon,
  Li,
  LiTitle,
  Line,
  OrText,
  StyledButton,
} from './drop-photo.styles';

interface DropPhotoProps {
  onChange: (file?: SelectedFile) => void;
}

export interface SelectedFile {
  url?: string;
  file?: File;
}

export const DropPhoto = memo<DropPhotoProps>((props) => {
  const { onChange } = props;
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      if (file) {
        onChange({
          file,
          url: URL.createObjectURL(new Blob([file], { type: file?.type })),
        });
      }
    },
    [onChange]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  return (
    <Stack
      {...getRootProps()}
      width={648}
      gap={4}
      justifyContent="center"
      alignItems="center"
      borderRadius={2}
      padding={5}
      border="1px dashed #AEAEAE"
    >
      <Stack justifyContent="center" alignItems="center" gap={1}>
        <LiTitle>Рекомендации к фото:</LiTitle>
        <Li>Дом полностью в кадре</Li>
        <Li>Дом не загораживают другие постройки и материалы</Li>
        <Li>Фото четкое и сделано при дневном свете</Li>
        <Li>Фото без водяных знаков</Li>
      </Stack>
      <ImageIcon />

      <Stack justifyContent="center" alignItems="center" gap={3}>
        <Typography variant="body2">
          Перетащите или загрузите файлы в данную область
        </Typography>

        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          gap={2}
        >
          <Line />
          <OrText>или</OrText>
          <Line />
        </Stack>
        <Stack justifyContent="center" alignItems="center" gap={1}>
          <StyledButton variant="contained">
            Добавить изображение <input {...getInputProps()} />
          </StyledButton>
          <Format>JPG, JPEG, PNG. До 15 МБ</Format>
        </Stack>
      </Stack>
    </Stack>
  );
});
