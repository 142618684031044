import { ApiUrls } from '@modules/constants';

import { adminAxios } from '../admin-axios';
import { ProductResponseDto, VisualType } from './types';

interface EditProductSuccessResponseData extends ProductResponseDto {}

interface EditProductRequestParams {
  productId: string;
  name: string;
  sku: string;
  scale: string;
  color?: string;
  categoryId: string;
  subCategoryId: string;
  visualType: VisualType;
}

export const editProduct = ({
  productId,
  name,
  sku,
  scale,
  color,
  visualType,
  categoryId,
  subCategoryId,
}: EditProductRequestParams) => {
  return adminAxios.put<EditProductSuccessResponseData>(
    ApiUrls.ADMIN_PRODUCT_BY_ID(productId),
    {
      name,
      sku,
      scale,
      visualType,
      ...(visualType ? { color } : {}),
      category: categoryId,
      ...(subCategoryId ? { subCategory: subCategoryId } : {}),
    }
  );
};
