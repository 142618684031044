import { useSnackbar } from 'notistack';
import { memo } from 'react';
import { useMutation } from 'react-query';

import { deleteSubcategory } from '@adapters/admin/subcategories';
import { queryClient } from '@modules/query-client';
import { DeleteDialog } from '@ui-kit/delete-dialog';

interface DeleteSubcategoryDialogProps {
  onClose: () => void;
  categoryId?: string;
  subcategoryId?: string;
}

export const DeleteSubcategoryDialog = memo<DeleteSubcategoryDialogProps>(
  ({ onClose, categoryId, subcategoryId }) => {
    const { enqueueSnackbar } = useSnackbar();

    const { mutate: mutateDeleteSubcategory } = useMutation(deleteSubcategory, {
      onSuccess: () => {
        enqueueSnackbar('Подкатегория успешно удалена', {
          variant: 'success',
        });
        queryClient.invalidateQueries();
        onClose();
      },
      onError: () => {
        enqueueSnackbar('Произошла ошибка', {
          variant: 'error',
        });
        queryClient.invalidateQueries();
        onClose();
      },
    });
    if (!categoryId || !subcategoryId) {
      return <></>;
    }
    return (
      <DeleteDialog
        title="Удалить подкатегорию?"
        subtitle="Подкатегорию нельзя будет восстановить."
        open={!!subcategoryId}
        onClose={onClose}
        onConfirm={() => {
          mutateDeleteSubcategory({ categoryId, id: subcategoryId });
        }}
      />
    );
  }
);
