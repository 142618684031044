import { FC, useEffect, useMemo, useState } from 'react';
import { useController, useWatch } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useDebounce } from 'use-debounce';

import {
  getSubcategories,
  getSubcategory,
} from '@adapters/admin/subcategories';
import { Autocomplete, TextField } from '@mui/material';

import { FormValues } from '../types';

const MAX_ROWS = 40;

export const SubcategoryAutocomplete: FC = () => {
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 1300);
  const categoryId = useWatch<FormValues>({ name: 'categoryId' });
  const page = 1;

  const { data: subcategoriesData } = useQuery(
    ['subcategories', categoryId, page, debouncedSearch],
    () =>
      categoryId
        ? getSubcategories({
            categoryId,
            page,
            pageSize: MAX_ROWS,
            search: debouncedSearch,
          })
        : undefined
  );

  const {
    field: { value: subcategoryId, onChange },
  } = useController<FormValues>({
    name: 'subcategoryId',
  });

  useEffect(() => {
    if (!categoryId && subcategoryId) {
      onChange('');
    }
  }, [categoryId, subcategoryId, onChange]);

  const { data: subcategoryData } = useQuery(
    ['subcategory', categoryId, subcategoryId],
    () =>
      categoryId && subcategoryId
        ? getSubcategory({ categoryId, subcategoryId })
        : undefined
  );

  const { optionsNames, options } = useMemo(() => {
    const optionsNames = new Map<string, string>();
    if (subcategoryData) {
      const { id, name } = subcategoryData.data;
      optionsNames.set(id, name);
    }
    if (subcategoriesData) {
      subcategoriesData.data.data.forEach(({ id, name }) => {
        optionsNames.set(id, name);
      });
    }
    return {
      optionsNames,
      options: Array.from(optionsNames.keys()),
    };
  }, [subcategoryData, subcategoriesData]);

  return (
    <Autocomplete
      options={options}
      value={subcategoryId || ''}
      onChange={(event, value) => {
        onChange(value);
      }}
      getOptionLabel={(option) => optionsNames.get(option) || ''}
      getOptionKey={(option) => option || 'key'}
      fullWidth
      inputValue={search}
      onInputChange={(e, value) => {
        setSearch(value);
      }}
      noOptionsText="Подкатегория не найдена"
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Выбрать подкатегорию"
          label="Выбрать подкатегорию"
        />
      )}
    />
  );
};
