import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { getCategory } from '@adapters/admin/categories';

export const useCategory = () => {
  const { categoryId } = useParams();
  const { data, isSuccess } = useQuery(['category', categoryId], () => {
    if (categoryId) {
      return getCategory({
        categoryId,
      });
    }
  });

  if (!isSuccess) {
    return undefined;
  }

  return data?.data.name;
};
