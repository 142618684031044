import React from 'react';
import ReactDOM from 'react-dom/client';

import Root from './core/root';
import reportWebVitals from './report-web-vitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(<Root />);

reportWebVitals();
