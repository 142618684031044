import { useSnackbar } from 'notistack';
import { memo } from 'react';
import { useMutation } from 'react-query';

import { createCategory } from '@adapters/admin/categories';
import { CombinationPartType } from '@modules/constants';
import { queryClient } from '@modules/query-client';
import { Dialog } from '@ui-kit/dialog';

import { CategoryForm } from './components/category-form';

interface CategoryFormDialogProps {
  onClose: () => void;
  open: boolean;
}

export const CreateCategoryFormDialog = memo<CategoryFormDialogProps>(
  (props) => {
    const { onClose, open = true } = props;
    const { enqueueSnackbar } = useSnackbar();
    const { mutate } = useMutation(createCategory, {
      onSuccess: () => {
        enqueueSnackbar('Категория успешно создана', {
          variant: 'success',
        });
        queryClient.invalidateQueries();
      },
      onError: () => {
        enqueueSnackbar('Произошла ошибка', {
          variant: 'error',
        });
      },
    });

    return (
      <Dialog title="Добавить категорию" open={open} onClose={onClose}>
        <CategoryForm
          onSubmit={(data) => {
            onClose();
            mutate({
              name: data.name,
              parts: data.parts?.split(',') as CombinationPartType[],
            });
          }}
        />
      </Dialog>
    );
  }
);
