import { useSnackbar } from 'notistack';
import { memo } from 'react';
import { useMutation } from 'react-query';

import { deleteProduct } from '@adapters/admin/products';
import { queryClient } from '@modules/query-client';
import { DeleteDialog } from '@ui-kit/delete-dialog';

interface DeleteCategoryDialogProps {
  onClose: () => void;
  productId?: string;
}

export const DeleteProductDialog = memo<DeleteCategoryDialogProps>(
  ({ onClose, productId }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { mutate: mutateDeleteProduct } = useMutation(deleteProduct, {
      onSuccess: () => {
        enqueueSnackbar('Продукт успешно удален', {
          variant: 'success',
        });
        queryClient.invalidateQueries();
        onClose();
      },
      onError: () => {
        enqueueSnackbar('Произошла ошибка', {
          variant: 'error',
        });
        queryClient.invalidateQueries();
        onClose();
      },
    });
    if (!productId) {
      return <></>;
    }
    return (
      <DeleteDialog
        title="Удалить Продукт?"
        subtitle="Продукт нельзя будет восстановить."
        open={!!productId}
        onClose={onClose}
        onConfirm={() => {
          mutateDeleteProduct({ productId });
        }}
      />
    );
  }
);
