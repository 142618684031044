import { Box, Stack, styled } from '@mui/material';

export const Container = styled(Stack)`
  width: 100%;
  @media print {
    display: none;
  }
`;

export const ItemContainer = styled(Box)`
  width: calc((100% - 40px) / 5);
  overflow: hidden;
  aspect-ratio: 2 / 1;
  box-sizing: border-box;
  margin-left: 10px;
  border-radius: 8px;
  cursor: pointer;
  &:first-of-type {
    margin-left: 0px;
  }
`;
