import { ApiUrls } from '@modules/constants';

import { adminAxios } from '../admin-axios';
import { ProductResponseDto } from './types';

interface CreateProductSuccessResponseData extends ProductResponseDto {}

export interface UploadImageRequestParams {
  file: File;
  productId: string;
}

export const uploadImage = ({ file, productId }: UploadImageRequestParams) => {
  const formData = new FormData();

  formData.append('file', file);

  return adminAxios.post<CreateProductSuccessResponseData>(
    ApiUrls.ADMIN_PRODUCT_UPLOAD(productId),
    formData
  );
};
