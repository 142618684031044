export default (
  <>
    <path
      d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 16.5C12 17.39 11.7361 18.26 11.2416 19.0001C10.7471 19.7401 10.0443 20.3169 9.22208 20.6575C8.39981 20.9981 7.49501 21.0872 6.6221 20.9135C5.74918 20.7399 4.94736 20.3113 4.31802 19.682C3.68868 19.0526 3.2601 18.2508 3.08647 17.3779C2.91283 16.505 3.00195 15.6002 3.34254 14.7779C3.68314 13.9557 4.25991 13.2529 4.99994 12.7584C5.73996 12.2639 6.60999 12 7.5 12C6.60999 12 5.73996 11.7361 4.99994 11.2416C4.25991 10.7471 3.68314 10.0443 3.34254 9.22208C3.00195 8.39981 2.91283 7.49501 3.08647 6.6221C3.2601 5.74918 3.68868 4.94736 4.31802 4.31802C4.94736 3.68868 5.74918 3.2601 6.6221 3.08647C7.49501 2.91283 8.39981 3.00195 9.22208 3.34254C10.0443 3.68314 10.7471 4.25991 11.2416 4.99994C11.7361 5.73996 12 6.60999 12 7.5C12 6.60999 12.2639 5.73996 12.7584 4.99994C13.2529 4.25991 13.9557 3.68314 14.7779 3.34254C15.6002 3.00195 16.505 2.91283 17.3779 3.08647C18.2508 3.2601 19.0526 3.68868 19.682 4.31802C20.3113 4.94736 20.7399 5.74918 20.9135 6.6221C21.0872 7.49501 20.9981 8.39981 20.6575 9.22208C20.3169 10.0443 19.7401 10.7471 19.0001 11.2416C18.26 11.7361 17.39 12 16.5 12C17.39 12 18.26 12.2639 19.0001 12.7584C19.7401 13.2529 20.3169 13.9557 20.6575 14.7779C20.9981 15.6002 21.0872 16.505 20.9135 17.3779C20.7399 18.2508 20.3113 19.0526 19.682 19.682C19.0526 20.3113 18.2508 20.7399 17.3779 20.9135C16.505 21.0872 15.6002 20.9981 14.7779 20.6575C13.9557 20.3169 13.2529 19.7401 12.7584 19.0001C12.2639 18.26 12 17.39 12 16.5Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 7.5V9"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.5 12H9"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5 12H15"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 16.5V15"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 8L9.88 9.88"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.1201 9.88L16.0001 8"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 16.0001L9.88 14.1201"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.1201 14.1201L16.0001 16.0001"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
