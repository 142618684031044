import { AxiosResponse } from 'axios';

import { ApiUrls } from '@modules/constants';

import { adminAxios } from '../admin-axios';
import { CategoryResponseDto } from './types';

const MAX_ROWS = 100;

interface GetAllCategoriesSuccessResponseData {
  total: number;
  data: CategoryResponseDto[];
}

export const getAllCategories = async () => {
  const data: CategoryResponseDto[] = [];

  let total = 0;

  let response:
    | AxiosResponse<GetAllCategoriesSuccessResponseData, any>
    | undefined = undefined;

  do {
    const page = Math.floor(data.length / MAX_ROWS) + 1;

    response = await adminAxios.get<GetAllCategoriesSuccessResponseData>(
      ApiUrls.ADMIN_CATEGORIES,
      {
        params: {
          page,
          pageSize: MAX_ROWS,
        },
      }
    );

    total = response.data.total;
    response.data.data.forEach((category) => {
      data.push(category);
    });
  } while (data.length < total);

  return { ...response, data: { ...response.data, data } };
};
