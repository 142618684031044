import { useCallback, useMemo, useState } from 'react';
import { useMutation } from 'react-query';

import { deleteUserProject } from '@adapters/user/project';
import { queryClient } from '@modules/query-client';
import { AppStorage } from '@modules/storage';

export const useDeleteProject = () => {
  const [isDeleteDialog, setIsDeleteDialog] = useState(false);

  const { mutate } = useMutation(deleteUserProject, {
    onSuccess: (data) => {
      queryClient.clear();
      AppStorage.remove('USER_AUTH');
    },
    onError: () => {
      queryClient.clear();
      AppStorage.remove('USER_AUTH');
    },
  });

  const openDialog = useCallback(() => {
    setIsDeleteDialog(true);
  }, [setIsDeleteDialog]);

  const onDeleteDialogClose = useCallback(() => {
    setIsDeleteDialog(false);
  }, []);

  return useMemo(() => {
    return {
      isDeleteDialog,
      openDialog,
      onDelete: mutate,
      onDeleteDialogClose,
    };
  }, [isDeleteDialog, openDialog, mutate, onDeleteDialogClose]);
};
