import { TableCell as MUITableCell, styled } from '@mui/material';

export const Img = styled('img')`
  width: 184px;
  height: 36px;
  min-width: 184px;
  object-fit: cover;
  overflow: hidden;
`;

export const ErrorBlock = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 184px;
  min-width: 184px;
  height: 35px;
  overflow: hidden;
  color: red;
  border: 1px solid red;
`;

export const ColorBlock = styled('div')`
  width: 184px;
  min-width: 184px;
  height: 35px;
  border: 1px solid gray;
`;

export const TableCell = styled(MUITableCell)`
  padding: 9px 15px;
  height: 56px;
  overflow: hidden;
`;
