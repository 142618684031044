import { ApiUrls, CombinationPartType } from '@modules/constants';

import { adminAxios } from '../admin-axios';
import { CategoryResponseDto } from './types';

interface EditCategorySuccessResponseData extends CategoryResponseDto {}

interface EditCategoryRequestParams {
  name: string;
  categoryId: string;
  parts?: CombinationPartType[];
}

export const editCategory = ({
  categoryId,
  name,
  parts,
}: EditCategoryRequestParams) => {
  const p = parts?.filter((v) => !!v);
  return adminAxios.put<EditCategorySuccessResponseData>(
    ApiUrls.ADMIN_CATEGORY_BY_ID(categoryId),
    {
      name,
      ...(p?.length ? { parts: p } : {}),
    }
  );
};
