import EventSource from 'react-native-sse';

import { BASE_API } from '@modules/constants';

export class UserSSE<Message extends {}> {
  private readonly sse: EventSource;

  constructor(endpoint: string) {
    this.sse = new EventSource(`${BASE_API}${endpoint}`);
  }

  onMessage(listener: (message: Message) => void) {
    this.sse.addEventListener('message', async (event) => {
      const message = await JSON.parse(event.data || '{}');
      listener(message);
    });
  }

  onOpen(listener: () => void) {
    this.sse.addEventListener('open', listener);
  }
  onClose(listener: () => void) {
    this.sse.addEventListener('close', listener);
  }
  onError(listener: () => void) {
    this.sse.addEventListener('error', listener);
  }
  complete() {
    this.sse.removeAllEventListeners();
    this.sse.close();
  }
}
