import { memo } from 'react';
import { useForm } from 'react-hook-form';

import { Button, Stack, TextField } from '@mui/material';

interface SubcategoryFormProps {
  onSubmit: (data: Form) => void;
  defaultValues?: Form;
  isEdit?: boolean;
}

interface Form {
  name: string;
}

export const SubcategoryForm = memo<SubcategoryFormProps>((props) => {
  const { onSubmit, defaultValues } = props;
  const {
    formState,
    register,
    handleSubmit: formSubmit,
  } = useForm<Form>({ defaultValues });

  const handleSubmit = (data: Form) => {
    onSubmit(data);
  };

  return (
    <form onSubmit={formSubmit(handleSubmit)}>
      <Stack direction="column" spacing={2}>
        <TextField
          {...register('name', { required: true, minLength: 2 })}
          placeholder="Введите название подкатегории"
          label="Название подкатегории"
        />
        <Stack direction="row" justifyContent="flex-end">
          <Button
            type="submit"
            disabled={!formState.isValid}
            variant="contained"
            size="small"
          >
            {props.isEdit ? 'Изменить' : 'Добавить'}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
});
