import { useSnackbar } from 'notistack';
import { memo, useRef } from 'react';
import { useMutation } from 'react-query';

import {
  VisualType,
  createProduct,
  uploadImage,
} from '@adapters/admin/products';
import { queryClient } from '@modules/query-client';
import { Dialog } from '@ui-kit/dialog';
import { SelectedFile } from '@ui-kit/file-picker';

import { ProductForm } from './components/product-form';

interface ProductFormDialogProps {
  onClose: () => void;
  open: boolean;
}

export const CreateProductDialog = memo<ProductFormDialogProps>((props) => {
  const { onClose, open = true } = props;
  const { enqueueSnackbar } = useSnackbar();
  const selectedFile = useRef<SelectedFile | undefined>(undefined);
  const { mutate: uploadImageMutate } = useMutation(uploadImage, {
    onSuccess: () => {
      enqueueSnackbar('Продукт успешно создана', {
        variant: 'success',
      });
      queryClient.invalidateQueries();
      onClose();
    },
    onError: () => {
      enqueueSnackbar('Продукт успешно создана', {
        variant: 'success',
      });
      queryClient.invalidateQueries();
      enqueueSnackbar('Не удалось загрузить картинку', {
        variant: 'error',
      });
      onClose();
    },
  });

  const { mutate: createProductMutate } = useMutation(createProduct, {
    onSuccess: (data) => {
      if (selectedFile.current?.file) {
        uploadImageMutate({
          file: selectedFile.current.file,
          productId: data.data.id,
        });
        return;
      }
      enqueueSnackbar('Продукт успешно создана', {
        variant: 'success',
      });

      queryClient.invalidateQueries();
      onClose();
    },
    onError: () => {
      enqueueSnackbar('Произошла ошибка', {
        variant: 'error',
      });
    },
  });

  return (
    <Dialog title="Добавить продукцию" open={open} onClose={onClose}>
      <ProductForm
        onSubmit={(data, file) => {
          selectedFile.current =
            data.visualType === VisualType.COLOR ? undefined : file;
          createProductMutate({
            name: data.name,
            categoryId: data.categoryId,
            subCategoryId: data.subcategoryId,
            sku: data.vendorCode,
            scale: data.scale,
            visualType: data.visualType,
            color: data.visualType === VisualType.COLOR ? data.color : '',
          });
        }}
      />
    </Dialog>
  );
});
