import { ApiUrls } from '@modules/constants';

import { userAxios } from '../user-axios';

interface LoginSuccessResponseData {
  accessToken: 'string';
}

export const login = () => {
  return userAxios.post<LoginSuccessResponseData>(ApiUrls.USER_LOGIN);
};
