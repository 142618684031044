import Lottie from 'lottie-react';
import { FC } from 'react';

import { Stack } from '@mui/material';
import { Animation } from '@ui-kit/animation';

export const Loader: FC = () => {
  return (
    <Stack justifyContent="center" alignItems="center">
      <Lottie
        style={{ flexShrink: 1, flexGrow: 1 }}
        animationData={Animation.Loader}
      />
    </Stack>
  );
};
