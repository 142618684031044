import { SnackbarProvider } from 'notistack';
import type { FC } from 'react';
import { RouterProvider } from 'react-router-dom';

import { QueryClientProvider, queryClient } from '@modules/query-client';

import { routes } from '../router';
import Theme from '../wrapper/theme';
import './root.css';

export const Root: FC = () => {
  return (
    <div id="page">
      <Theme>
        <SnackbarProvider>
          <QueryClientProvider client={queryClient}>
            <RouterProvider router={routes} />
          </QueryClientProvider>
        </SnackbarProvider>
      </Theme>
    </div>
  );
};
