import { VisualType } from '@adapters/admin/products';
import { ApiUrls, CombinationPartType } from '@modules/constants';

import { userAxios } from '../user-axios';

interface CombinationPart {
  id: string;
  part: CombinationPartType;
  isVisible: boolean;
  product: {
    id: string;
    name: string;
    sku: string;
    color: string;
    visualType: VisualType;
    media: {
      url: string;
    };
  };
}

interface Combination {
  name: string;
  description: string;
  isVisible: boolean;
  id: string;
  combinationParts: CombinationPart[];
}

interface DecorMedia {
  id: string;
  url: string;
  decorators: string[];
}

interface GetProductByIdSuccessResponseData {
  id: string;
  createdAt: string;
  author: string;
  combination: Combination;
  srcMedia: {
    url: string;
  };
  distMedia: {
    url: string;
  };
  decorMedia: DecorMedia[];
}

export const getProjectById = (productId: string) => {
  return userAxios.get<GetProductByIdSuccessResponseData>(
    ApiUrls.USER_PROJECT_BY_ID(productId)
  );
};
