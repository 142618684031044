import { ApiUrls } from '@modules/constants';

import { adminAxios } from '../admin-axios';
import { SubcategoryResponseDto } from './types';

interface CreateSubcategoriesSuccessResponseData
  extends SubcategoryResponseDto {}

interface CreateSubcategoryRequestParams {
  categoryId: string;
  name: string;
}

export const createSubcategory = ({
  categoryId,
  name,
}: CreateSubcategoryRequestParams) => {
  return adminAxios.post<CreateSubcategoriesSuccessResponseData>(
    ApiUrls.ADMIN_SUBCATEGORIES(categoryId),
    {
      category: categoryId,
      name,
    }
  );
};
