import { ApiUrls } from '@modules/constants';

import { UserSSE } from '../user-sse';

interface EventProjectProps {
  projectId: string;
}

interface EventProjectMessage {
  projectId: string;
  decorators?: string;
  errorMessage?: 'IMAGE_ERROR' | 'DECORATOR_ERROR';
}

export const eventProject = ({ projectId }: EventProjectProps) => {
  return new UserSSE<EventProjectMessage>(
    ApiUrls.USER_SUBSCRIBE_PROJECT(projectId)
  );
};
