import { memo, useId } from 'react';
import 'react-color-palette/css';
import { useController, useWatch } from 'react-hook-form';

import { VisualType } from '@adapters/admin/products';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';
import { SelectedFile } from '@ui-kit/file-picker';

import { ColorPalette } from '../color-palette';
import { ImagePicker } from '../image-picker';
import { FormValues } from '../types';

interface ColorModeProps {
  onChangeFile: (value?: SelectedFile | undefined) => void;
}

export const ColorMode = memo<ColorModeProps>((props) => {
  const { onChangeFile } = props;
  const id = useId();

  const visualType =
    useWatch<FormValues>({ name: 'visualType' }) || VisualType.IMAGE;

  const {
    field: { value, onChange },
  } = useController<FormValues>({
    name: 'visualType',
  });

  return (
    <>
      <Grid item xs={12} lg={12}>
        <FormControl>
          <FormLabel id={id}> </FormLabel>
          <RadioGroup
            row
            aria-labelledby={id}
            value={value || VisualType.IMAGE}
            onChange={onChange}
          >
            <FormControlLabel
              value={VisualType.IMAGE}
              control={<Radio />}
              label="Изображение"
            />
            <FormControlLabel
              value={VisualType.COLOR}
              control={<Radio />}
              label="Цвет"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      {visualType === VisualType.COLOR && (
        <Grid item xs={12} lg={12}>
          <ColorPalette />
        </Grid>
      )}
      {visualType === VisualType.IMAGE && (
        <Grid item xs={12} lg={4}>
          <ImagePicker onChangeFile={onChangeFile} />
        </Grid>
      )}
    </>
  );
});
