import { memo } from 'react';

import './combination-name.styles';
import { Block, Container } from './combination-name.styles';

interface CombinationNameProps {
  text: string;
}

export const CombinationName = memo<CombinationNameProps>(({ text }) => {
  return (
    <Container>
      <Block>{text}</Block>
    </Container>
  );
});
