import { ApiUrls } from '@modules/constants';

import { userAxios } from '../user-axios';

type DeleteUserProjectSuccessResponseData = boolean;

export const deleteUserProject = () => {
  return userAxios.delete<DeleteUserProjectSuccessResponseData>(
    ApiUrls.USER_PROJECTS
  );
};
