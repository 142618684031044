import { ApiUrls } from '@modules/constants';

import { adminAxios } from '../admin-axios';

interface DeleteCategoryRequestParams {
  id: string;
}

export const deleteCategory = ({ id }: DeleteCategoryRequestParams) => {
  return adminAxios.delete<boolean>(ApiUrls.ADMIN_CATEGORY_BY_ID(id));
};
