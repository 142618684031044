import { memo } from 'react';

import { IconButton } from '@mui/material';
import Icons from '@ui-kit/icons';

import { Container, TableCell } from './cell-controls.style';

interface TableControlsProps {
  onEdit?: () => void;
  onDelete?: () => void;
}

export const CellControls = memo<TableControlsProps>(({ onEdit, onDelete }) => {
  return (
    <TableCell>
      <Container>
        {onEdit && (
          <IconButton
            aria-label="delete"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              onEdit();
            }}
          >
            <Icons.Edit size={24} />
          </IconButton>
        )}
        {onDelete && (
          <IconButton
            aria-label="delete"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
          >
            <Icons.Trash size={24} />
          </IconButton>
        )}
      </Container>
    </TableCell>
  );
});
