import { FC } from 'react';

import Icons from '@ui-kit/icons';

interface Decoration {
  id: string;
  label?: string;
  icon: FC<{ size: number }>;
}

export const TIMES_OF_DAY: Decoration[] = [
  {
    id: 'day',
    icon: Icons.Sun,
  },
  {
    id: 'night',
    icon: Icons.Moon,
  },
];

export const SEASON: Decoration[] = [
  {
    id: 'winter',
    icon: Icons.Winter,
    label: 'Зима',
  },
  {
    id: 'spring',
    icon: Icons.Spring,
    label: 'Весна',
  },
  {
    id: 'summer',
    icon: Icons.Summer,
    label: 'Лето',
  },
  {
    id: 'autumn',
    icon: Icons.Autumn,
    label: 'Осень',
  },
];
