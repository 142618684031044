import { memo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { VisualType } from '@adapters/admin/products';
import { trimRequired } from '@modules/utils';
import { Box, Button, Grid, Stack, TextField } from '@mui/material';
import { SelectedFile } from '@ui-kit/file-picker';

import { CategoryAutocomplete } from '../category-autocomplete';
import { ColorMode } from '../color-mode';
import { SubcategoryAutocomplete } from '../subcategory-autocomplete';
import { FormValues } from '../types';

interface ProductFormProps {
  onSubmit: (data: FormValues, file: SelectedFile | undefined) => void;
  defaultForm?: FormValues;
}

export const ProductForm = memo<ProductFormProps>((props) => {
  const { onSubmit, defaultForm } = props;

  const methods = useForm<FormValues>({
    defaultValues:
      defaultForm ||
      ({
        visualType: VisualType.IMAGE,
      } as FormValues),
  });
  const { formState, register, handleSubmit: formSubmit } = methods;
  const [file, setFile] = useState<SelectedFile | undefined>();

  const handleSubmit = (data: FormValues) => {
    onSubmit(data, file);
  };

  const handleChangeFile = (value?: SelectedFile | undefined) => {
    setFile(value);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={formSubmit(handleSubmit)}>
        <Stack direction="column" spacing={2}>
          <TextField
            {...register('name', {
              required: true,
              minLength: 2,
              validate: {
                trimRequired,
              },
            })}
            placeholder="Введите название продукции"
            label="Название продукции"
          />

          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <CategoryAutocomplete />
              </Grid>
              <Grid item xs={12} lg={6}>
                <SubcategoryAutocomplete />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  fullWidth
                  {...register('vendorCode', {
                    required: true,
                    validate: {
                      trimRequired,
                    },
                  })}
                  placeholder="Введите артикул"
                  label="Артикул *"
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  fullWidth
                  {...register('scale', {
                    required: true,
                    validate: {
                      trimRequired,
                    },
                  })}
                  placeholder="Введите масштаб"
                  label="Масштаб *"
                />
              </Grid>
              <ColorMode onChangeFile={handleChangeFile} />
            </Grid>
          </Box>

          <Stack direction="row" justifyContent="flex-end">
            <Button
              type="submit"
              disabled={!formState.isValid}
              variant="contained"
              size="small"
            >
              Сохранить
            </Button>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
});
