import { memo, useEffect, useRef, useState } from 'react';

import { Add, ModeEdit } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';

import {
  StyledButton,
  StyledContainer,
  StyledInput,
} from './file-picker.styles';

interface FileUploaderProps {
  onChange?: (file?: SelectedFile) => void;
  defaultUrls?: string;
  label?: string;
  isButton?: boolean
}

export interface SelectedFile {
  url?: string;
  file?: File;
}

export const FilePicker = memo<FileUploaderProps>((props) => {
  const { onChange, defaultUrls, label, isButton = false } = props;
  const fileRef = useRef<HTMLInputElement | null>(null);
  const [selectedFile, setSelectedFile] = useState<SelectedFile | undefined>();

  useEffect(() => {
    if (onChange) {
      onChange(selectedFile);

      if (isButton) {
        setSelectedFile(undefined);
      }
    }
    // eslint-disable-next-line
  }, [selectedFile]);

  const handleChange = () => {
    const file = fileRef?.current?.files?.[0];

    if (file) {
      setSelectedFile({
        file,
        url: URL.createObjectURL(new Blob([file], { type: file?.type })),
      });
    }
  };

  const imgUrl = selectedFile?.url || defaultUrls;

  return (
    <Stack>
      {!!label && (
        <Typography variant="caption" mb={1}>
          {label}
        </Typography>
      )}

      <StyledContainer>
        <StyledButton
          sx={{
            border: 'none',
            backgroundImage: imgUrl ? `url(${imgUrl})` : undefined,
          }}
          fullWidth
          variant="outlined"
        >
          {!selectedFile?.url && <Add />}
          {!!selectedFile?.url && <ModeEdit />}
          <StyledInput
            ref={fileRef}
            onChange={handleChange}
            accept="image/*"
            multiple={false}
            type="file"
          />
        </StyledButton>
      </StyledContainer>
    </Stack>
  );
});
