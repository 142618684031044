import { ApiUrls } from '@modules/constants';

import { adminAxios } from '../admin-axios';
import { CombinationResponseDto } from './types';

interface GetCombinationsSuccessResponseData {
  total: number;
  data: CombinationResponseDto[];
}

interface GetCombinationsRequestParams {
  page: number;
  pageSize: number;
  search?: string;
}

export const getCombinations = ({
  page,
  pageSize,
  search,
}: GetCombinationsRequestParams) => {
  return adminAxios.get<GetCombinationsSuccessResponseData>(
    ApiUrls.ADMIN_COMBINATIONS,
    {
      params: {
        page,
        pageSize,
        sorts: 'name:asc',
        ...(search ? { search } : {}),
      },
    }
  );
};
