import { memo } from 'react';
import { useMutation } from 'react-query';

import { createProject } from '@adapters/user/project';
import { queryClient } from '@modules/query-client';
import { CircularProgress, Paper, Stack, Typography } from '@mui/material';

import { DropPhoto } from './drop-photo';
import { LeftInfo } from './left-info';

interface LoadPhotoProps {
  onLoaded: () => void;
}

export const LoadPhoto = memo<LoadPhotoProps>(({ onLoaded }) => {
  const { mutate: uploadImageMutate, isLoading } = useMutation(createProject, {
    onSuccess: () => {
      queryClient.clear();
      onLoaded();
    },
    onError: () => {
      queryClient.clear();
      onLoaded();
    },
  });

  return (
    <Paper sx={{ width: '100%', height: 552, minWidth: 1120, minHeight: 552 }}>
      <Stack
        width="100%"
        height="100%"
        gap={4}
        direction="row"
        alignContent="center"
        justifyContent="center"
      >
        <LeftInfo />
        {isLoading ? (
          <Stack
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
            gap={2}
          >
            <Typography variant="body1">Загружается...</Typography>
            <CircularProgress />
          </Stack>
        ) : (
          <DropPhoto
            onChange={(selectedFile) => {
              const file = selectedFile?.file;
              file && uploadImageMutate({ file });
            }}
          />
        )}
      </Stack>
    </Paper>
  );
});
