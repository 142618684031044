import { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { getCategories } from '@adapters/admin/categories';
import { Urls } from '@modules/constants';
import {
  Alert,
  CircularProgress,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { CellControls, CellText } from '@ui-kit/table';
import { EditCategoryDialog } from '@widgets/category-form-dialog';
import { useHeaderContext } from '@widgets/content-header';

import { DeleteCategoryDialog } from './components/delete-category-dialog';

const ROWS_PER_PAGE = 40;

export const CategoryWidget: FC = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [deleteId, setDeleteId] = useState('');
  const [editId, setEditId] = useState('');

  const { debouncedSearch } = useHeaderContext();

  const { data, isError, isLoading } = useQuery(
    ['categories', page, debouncedSearch],
    () =>
      getCategories({ page, pageSize: ROWS_PER_PAGE, search: debouncedSearch })
  );

  const categoriesData = data?.data?.data || [];
  const pageTotal = Math.floor(
    (data?.data && data?.data?.total / ROWS_PER_PAGE + 1) || 1
  );

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (page < 1) {
      setPage(1);
    }
    if (page > pageTotal) {
      setPage(pageTotal);
    }
  }, [isLoading, page, pageTotal]);

  if (isError) {
    return (
      <Alert severity="error">Произошла ошибка при получении данных</Alert>
    );
  }

  if (isLoading) {
    return (
      <Stack
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100%"
      >
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <>
      <DeleteCategoryDialog
        categoryId={deleteId}
        onClose={() => {
          setDeleteId('');
        }}
      />
      <EditCategoryDialog
        categoryId={editId}
        onClose={() => setEditId('')}
        open={!!editId}
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 48 }}>№</TableCell>
              <TableCell scope="row">Название</TableCell>
              <TableCell style={{ width: 104 }}>Опции</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categoriesData.map((category, index) => (
              <TableRow
                hover
                key={category.id}
                onClick={() => navigate(Urls.ADMIN_CATEGORY.url(category.id))}
                sx={{ cursor: 'pointer' }}
              >
                <TableCell>{index + 1 + (page - 1) * ROWS_PER_PAGE}</TableCell>
                <CellText>{category.name}</CellText>
                <CellControls
                  onDelete={() => {
                    setDeleteId(category.id);
                  }}
                  onEdit={() => {
                    setEditId(category.id);
                  }}
                />
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          variant="outlined"
          count={pageTotal}
          page={page}
          onChange={(event, value) => {
            setPage(value);
          }}
          disabled={pageTotal < 2}
        />
      </TableContainer>
    </>
  );
};
