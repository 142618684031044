import { ApiUrls } from '@modules/constants';

import { adminAxios } from '../admin-axios';
import { CategoryResponseDto } from './types';

interface GetCategorySuccessResponseData extends CategoryResponseDto {}

interface GetCategoryRequestParams {
  categoryId: string;
}

export const getCategory = ({ categoryId }: GetCategoryRequestParams) => {
  return adminAxios.get<GetCategorySuccessResponseData>(
    ApiUrls.ADMIN_CATEGORY_BY_ID(categoryId)
  );
};
