import { memo } from 'react';

import { Stack, Tooltip } from '@mui/material';
import Icons from '@ui-kit/icons';

import { CombinationName } from '../combination-name';
import { ControlsButton, ShowButton } from './controls.styles';

interface ControlsProps {
  isShort: boolean;
  onSpecificationToggle: () => void;
  onRequestCostCalculation: () => void;
  onRepeat: () => void;
  onBack: () => void;
  isPending?: boolean;
  combinationName?: string;
}

export const Controls = memo<ControlsProps>(
  ({
    isShort,
    onSpecificationToggle,
    onRequestCostCalculation,
    onRepeat,
    onBack,
    isPending,
    combinationName,
  }) => {
    return (
      <Stack direction="row" justifyContent="space-between" gap={2}>
        <Stack flexShrink={0}>
          <Tooltip title="Вернуться на главную страницу">
            <span>
              <ControlsButton onClick={onBack}>
                <Icons.BackArrow size={24} />
              </ControlsButton>
            </span>
          </Tooltip>
        </Stack>

        {combinationName && isShort && (
          <CombinationName text={combinationName || ''} />
        )}

        <Stack direction="row" gap={2} flexShrink={0}>
          <Tooltip title="Скрыть материалы">
            <span>
              <ShowButton
                sx={{ rotate: isShort ? '180deg' : '0' }}
                disabled={isPending}
                onClick={onSpecificationToggle}
              >
                <Icons.UpArrow size={24} />
              </ShowButton>
            </span>
          </Tooltip>
          {!isShort && (
            <Tooltip title="Распечатать/скачать">
              <span>
                <ControlsButton
                  disabled={isPending}
                  variant="contained"
                  onClick={() => {
                    window.print();
                  }}
                >
                  <Icons.Download size={24} />
                </ControlsButton>
              </span>
            </Tooltip>
          )}
          <Tooltip title="Предложить ещё варианты">
            <span>
              <ControlsButton
                onClick={onRepeat}
                disabled={isPending}
                variant="contained"
              >
                <Icons.Repeat size={24} />
              </ControlsButton>
            </span>
          </Tooltip>
          {!isShort && (
            <Tooltip title="Заявка на расчет материалов">
              <span>
                <ControlsButton
                  disabled={isPending}
                  variant="contained"
                  onClick={onRequestCostCalculation}
                >
                  запросить расчет
                </ControlsButton>
              </span>
            </Tooltip>
          )}
        </Stack>
      </Stack>
    );
  }
);
