import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { Box, Grid, Stack } from '@mui/material';
import { Header } from '@widgets/header';
import { SideMenu } from '@widgets/side-menu';

export const AdminLayout: FC = () => {
  return (
    <Stack spacing={4} height="100%" pb="32px">
      <Header />
      <Box sx={{ height: '100%', px: 4 }}>
        <Grid container height="100%" spacing={4}>
          <Grid item xs={3}>
            <SideMenu />
          </Grid>
          <Grid item xs={9}>
            <Outlet />
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};
