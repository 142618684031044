import { useEffect } from 'react';

export const useTimeOut = (
  active: boolean,
  onTimeout: () => void,
  time: number
) => {
  useEffect(() => {
    if (!active) {
      return;
    }
    const id = setTimeout(() => {
      onTimeout();
    }, time);
    return () => clearTimeout(id);
  }, [active, onTimeout, time]);
};
