import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect } from 'react';

import { eventProject } from '@adapters/user/project';
import { SSE_TIMEOUT } from '@modules/constants';
import { queryClient } from '@modules/query-client';

import { useTimeOut } from '.';
import { useUserProductState } from '../state';
import { useDeleteDecoration } from './use-delete-decoration';
import { useDeleteProjectById } from './use-delete-project-by-id';

export const useSubscribeProject = () => {
  const {
    state: {
      wait: { type, projectId },
    },
    dispatch,
  } = useUserProductState();

  const deleteProject = useDeleteProjectById();
  const deleteDecorator = useDeleteDecoration();

  const onTimeout = useCallback(() => {
    if (type === 'DECORATION') {
      enqueueSnackbar('Не удалось применить декоратор', {
        variant: 'error',
      });
      deleteDecorator();
    }
    if (type === 'PROJECT') {
      enqueueSnackbar(
        'Не удалось обработать эту фотографию. Попробуйте другую.',
        {
          variant: 'error',
        }
      );
      deleteProject();
    }
  }, [type, deleteDecorator, deleteProject]);

  useTimeOut(!!projectId, onTimeout, SSE_TIMEOUT);

  const onCheck = useCallback(() => {
    //так как возможны случаи, что событие может придти до того как мы подпишемся
    //следует перезапросить все запросы после установки соединения
    //Я предпочел бы делать это после установки соединения но бэк не устанавливает соединение, а держит в пендинге
    queryClient.invalidateQueries();
  }, []);

  useTimeOut(!!projectId, onCheck, 2000);

  useEffect(() => {
    if (!projectId) {
      return;
    }
    let isCancel = false;
    const es = eventProject({ projectId });

    es.onMessage((message) => {
      if (isCancel) {
        return;
      }
      isCancel = true;
      dispatch({ type: 'CompleteWaiting' });

      if (message.errorMessage === 'DECORATOR_ERROR') {
        enqueueSnackbar('Не удалось применить декоратор', {
          variant: 'error',
        });
      }
      if (message.errorMessage === 'IMAGE_ERROR') {
        enqueueSnackbar(
          'Не удалось обработать эту фотографию. Попробуйте другую.',
          {
            variant: 'error',
          }
        );
      }
    });

    es.onClose(() => {
      if (isCancel) {
        return;
      }
      isCancel = true;
      onTimeout();
    });
    es.onError(() => {
      if (isCancel) {
        return;
      }
      isCancel = true;
      onTimeout();
    });

    return () => {
      es.complete();
    };
  }, [projectId, onTimeout, dispatch]);
};
