import { useCallback } from 'react';
import { useMutation } from 'react-query';

import { deleteUserProjectById } from '@adapters/user/project';

import { useUserProductState } from '../state';

export const useDeleteProjectById = () => {
  const {
    state: {
      wait: { projectId },
    },
    dispatch,
  } = useUserProductState();

  const handleComplete = useCallback(() => {
    dispatch({ type: 'CompleteWaiting' });
    dispatch({ type: 'ClearDecoration' });
  }, [dispatch]);

  const { mutate: deleteProduct } = useMutation(deleteUserProjectById, {
    onSuccess: handleComplete,
    onError: handleComplete,
  });

  return useCallback(() => {
    projectId && deleteProduct(projectId);
  }, [projectId, deleteProduct]);
};
