import { ApiUrls } from '@modules/constants';

import { userAxios } from '../user-axios';

type DeleteUserDecoratorByIdSuccessResponseData = boolean;

interface DeleteUserDecoratorByIdProps {
  projectId: string;
  decoratorId: string;
}

export const deleteUserDecoratorById = ({
  projectId,
  decoratorId,
}: DeleteUserDecoratorByIdProps) => {
  return userAxios.delete<DeleteUserDecoratorByIdSuccessResponseData>(
    ApiUrls.USER_DECORATION_BY_ID(projectId, decoratorId)
  );
};
