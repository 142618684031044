import { useSnackbar } from 'notistack';
import { memo } from 'react';
import { useMutation } from 'react-query';

import { deleteCombination } from '@adapters/admin/combinations';
import { queryClient } from '@modules/query-client';
import { DeleteDialog } from '@ui-kit/delete-dialog';

interface DeleteCombinationDialogProps {
  onClose: () => void;
  combinationId?: string;
}

export const DeleteCombinationDialog = memo<DeleteCombinationDialogProps>(
  ({ onClose, combinationId }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { mutate: mutateDeleteCombination, isLoading } = useMutation(
      deleteCombination,
      {
        onSuccess: () => {
          enqueueSnackbar('Комбинация успешно удалена', {
            variant: 'success',
          });
          onClose();
          queryClient.invalidateQueries();
        },
        onError: () => {
          enqueueSnackbar('Произошла ошибка', {
            variant: 'error',
          });
          onClose();
          queryClient.invalidateQueries();
        },
      }
    );
    if (!combinationId) {
      return <></>;
    }
    return (
      <DeleteDialog
        isPending={isLoading}
        title="Удалить комбинацию?"
        subtitle="Комбинацию нельзя будет восстановить."
        open={!!combinationId}
        onClose={onClose}
        onConfirm={() => {
          mutateDeleteCombination({ id: combinationId });
        }}
      />
    );
  }
);
