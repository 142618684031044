export default (
  <>
    <path
      d="M4 20.5H20C20.5304 20.5 21.0391 20.2893 21.4142 19.9142C21.7893 19.5391 22 19.0304 22 18.5V8.5C22 7.96957 21.7893 7.46086 21.4142 7.08579C21.0391 6.71071 20.5304 6.5 20 6.5H12.07C11.7406 6.4983 11.4167 6.41525 11.1271 6.25824C10.8375 6.10123 10.5912 5.87512 10.41 5.6L9.59 4.4C9.40882 4.12488 9.1625 3.89877 8.8729 3.74176C8.58331 3.58475 8.25941 3.5017 7.93 3.5H4C3.46957 3.5 2.96086 3.71071 2.58579 4.08579C2.21071 4.46086 2 4.96957 2 5.5V18.5C2 19.6 2.9 20.5 4 20.5Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 10.5V14.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 10.5V12.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 10.5V16.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
