import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import { getProjects } from '@adapters/user/project';

import { TabItem } from '../components/tabs';

export const useProducts = (onEmpty: () => void) => {
  const [currentProductId, onChangeProduct] = useState('');
  const { data, isLoadingError, isSuccess, isLoading } = useQuery(
    'getProducts',
    getProjects
  );

  useEffect(() => {
    if (isLoadingError) {
      onEmpty();
      return;
    }
    if (!isSuccess) {
      return;
    }
    if (data?.data.total === 0) {
      onEmpty();
    }
  }, [data, isSuccess, isLoadingError, onEmpty]);

  return useMemo(() => {
    let tabItems: TabItem[] = [];
    let productId = currentProductId;

    if (isSuccess) {
      tabItems = data.data.data.map((p) => ({
        id: p.id,
        url: p.distMedia.url || p.srcMedia.url,
      }));
      if (!tabItems.some(({ id }) => id === productId)) {
        productId = '';
      }
    }
    if (!productId) {
      productId = tabItems[tabItems.length - 1]?.id || '';
    }

    return {
      isLoading,
      tabItems,
      productId,
      onChangeProduct,
    };
  }, [data, isSuccess, currentProductId, isLoading, onChangeProduct]);
};
