import { ApiUrls } from '@modules/constants';

import { adminAxios } from '../admin-axios';
import { SubcategoryResponseDto } from './types';

interface EditSubcategoriesSuccessResponseData extends SubcategoryResponseDto {}

interface EditSubcategoryRequestParams {
  categoryId: string;
  subcategoryId: string;
  name: string;
}

export const editSubcategory = ({
  categoryId,
  subcategoryId,
  name,
}: EditSubcategoryRequestParams) => {
  return adminAxios.put<EditSubcategoriesSuccessResponseData>(
    ApiUrls.ADMIN_SUBCATEGORY_BY_ID(categoryId, subcategoryId),
    {
      name,
      category: categoryId,
    }
  );
};
