import React, { FC } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { getMe } from '@adapters/admin/auth';
import { Urls } from '@modules/constants';
import { AppStorage } from '@modules/storage';
import { Logout } from '@mui/icons-material';
import {
  Avatar,
  Menu,
  MenuItem,
  Stack,
  Typography,
  styled,
} from '@mui/material';

const StyledAvatar = styled(Avatar)`
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  background: unset;
  color: #bdbdbd;
`;

export const UserProfile: FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  const { data } = useQuery('me', getMe);
  const userData = data?.data;

  const handleLogout = () => {
    AppStorage.remove('ADMIN_AUTH');
    navigate(Urls.ADMIN_LOGIN);
  };

  if (!userData) {
    return null;
  }

  return (
    <>
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        onClick={handleClick}
        sx={{ cursor: 'pointer' }}
      >
        <Typography sx={{ textTransform: 'capitalize' }} variant="body2">
          {userData.role}
        </Typography>
        <StyledAvatar />
      </Stack>
      <Menu
        sx={{ mt: 1 }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleLogout}>
          <Logout sx={{ mr: 1, width: '16px', height: '16px' }} />
          Выйти
        </MenuItem>
      </Menu>
    </>
  );
};
