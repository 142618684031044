'use client';

import { ReactNode } from 'react';

import { ThemeProvider, createTheme } from '@mui/material';

import { AutocompleteTheme } from './components-theme/autocomplete-theme';
import { TableTheme } from './components-theme/table-theme';

const theme = createTheme({
  palette: {
    primary: {
      main: '#A50023',
    },
    secondary: {
      main: '#f50057',
      dark: '#1D1B1F',
    },
    background: {
      paper: '#ffffff',
      default: '#F6F6F6',
    },
    warning: {
      main: '#A50023',
    },
  },
  shape: {
    borderRadius: 4,
  },
  components: {
    ...TableTheme,
    ...AutocompleteTheme,
    MuiDialog: {
      styleOverrides: {
        paper: {
          margin: '0px',
          maxWidth: '688px',
          minHeight: '238px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: '24px',
          boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.07)',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          padding: '12px 32px',
          boxShadow: 'none',
          ':hover': {
            boxShadow: 'none',
          },
        },
        contained: {
          color: '#fff',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          padding: '12px 16px !important',
        },
        root: {
          borderRadius: '24px !important',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '.MuiInputLabel-root': {
            top: '-4px',
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          '.MuiInputLabel-root': {
            top: '-4px',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: (props) => {
          return `
            padding: 16px 32px;
            background: ${props.theme.palette.background.paper};
            color: inherit;
          `;
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          padding: '0',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
  },
  typography: {
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
    subtitle1: {
      fontSize: '18px',
      fontWeight: 700,
    },
    subtitle2: {
      fontSize: '16px',
      fontWeight: 600,
    },
  },
});

export default function Theme({ children }: { children: ReactNode }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
