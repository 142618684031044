import { Button, styled } from '@mui/material';

export const ControlsButton = styled(Button)`
  height: 48px;
  padding: 12px 31px;
  @media print {
    display: none;
  }
`;

export const ShowButton = styled(ControlsButton)`
  background: #000;
  color: white;
  &:hover {
    background: #333;
  }
  &:disabled {
    color: white;
    opacity: 0.5;
  }
`;
